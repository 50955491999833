export const heatMapMockData = {
  centroid: {
    x: 43.5861530254534,
    y: 45.8840066805,
  },
  matrix: {
    global: '0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001000000000000000000000000114100000000000000000100112235411000000140100031112223349843111100310000011122374467754100111200000010111247554430000000000000100001112523100000000000000000011112010000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
    firstHalf: '0000000000001000000000000000000011000000000000000000001200000000000001000002010000000000020110344100000000001116955111100000000000199961001000000000002999930010000000000245898210000000000003234022111000000000020110100000000000000102001100100000000000110100000000000000000000000000',
    secondHalf: '9999999999999999999999999999999999319991999999999999912319199999999999999112441199999999999991437844999999371999397551119999999396429562223199999999499979939263999999999999999895399999999991695999523999999999914396593199999999999412958993999999999997991439999999999999999999999900',
    messiDemo: '0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000001000000000000000000000000000011210000000000001100001132341100000000000101112253454423000000000001211433446764221000000000132224536444743000000000003221355546753230000000000001122973644324000000001000002311426321121210000000000000133531100001000000000000000021211000000010000000000000000000000000000000000000000000000000000',
  },
}
