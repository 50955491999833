import { LABEL_CONFIGURATION, LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class TeamTrendExampleComponent implements OnInit {

        teamTrendData = {
          "amountMatchdays": 38,
          "matchDays": [
            {
              "away": "",
              "home": "G",
              "number": 1,
              "rival": "BET",
              "score": "2 - 0",
              "total": "G"
            }
          ],
          "title": "TENDENCIA DEL EQUIPO",
          "totals": {
            "away": {
              "drawn": "0/0%",
              "lost": "0/0%",
              "won": "2/100%"
            },
            "home": {
              "drawn": "0/0%",
              "lost": "0/0%",
              "won": "3/100%"
            },
            "total": {
              "drawn": "0/0%",
              "lost": "0/0%",
              "won": "5/100%"
            }
          }
        }

        constructor() {}
      }
    `,
  },
  {
    content: `
      <mc-team-trend-table [teamTrendData]="teamTrendData"></mc-team-trend-table>
    `,
  },
  {
    label: LABEL_CONFIGURATION,
    description: 'Changing the component texts.',
    content: `
      export class TeamTrendExampleComponent implements OnInit {

        teamTrendTexts = {
          headerRowTitle: 'Jornada',
          headerCellPrefix: 'J',
          rows: {
            away: 'Visitante',
            home: 'Local',
            total: 'Total'
          },
          matchDetail: {
            number: 'Jornada',
            location: 'Condición',
            rival: 'Rival',
            score: 'Resultado'
          },
          totalSummary: {
            title: 'Totales',
            wonMatches: 'Ganados',
            lostMatches: 'Perdidos',
            drawMatches: 'Empatados'
          }
        }

        constructor() {}
      }
    `,
  },
  {
    content: `
      <mc-team-trend-table [teamTrendTexts]="teamTrendTexts" [teamTrendData]="teamTrendData"></mc-team-trend-table>
    `,
  },
  {
    label: 'CSS',
    content: `
      // Existen dos "enum" que gestionan las clases CSS: MatchResultType para el color de las celdas
      // según resultado y RankType según la tendéncia del equipo.

      export enum MatchResultType {
        G = 'victory',
        E = 'tie',
        P = 'defeat'
      }

      export enum RankType {
        Up = 'pi-arrow-circle-up',
        Down = 'pi-arrow-circle-down',
        Equal = 'pi-circle-off',
      }
    `,
  },
]
