import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import * as mockData from './mockData.json'
import { DEV_TABS } from './spider-chart-page.constants'

@Component({
  selector: 'app-spider-chart-page',
  templateUrl: './spider-chart-page.component.html',
  styleUrls: ['./spider-chart-page.component.scss'],
})
export class SpiderChartPageComponent implements OnInit, AfterViewInit {
  spiderMockData = mockData['default']
  spiderData = mockData['default'].spiderData
  spiderDataNoValues = mockData['default'].spiderDataNoValues
  spiderHeader = mockData['default'].spiderHeader
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  @ViewChild('componentPlaceholder', { static: true }) componentPlaceholder: TemplateRef<any>

  render$ = new Subject()

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {

    this.overviewDevData = setOverviewDevData({
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCPF/Spider+Chart',
      visualsUrl: 'https://app.zeplin.io/project/58e60ec50c7ed3178713d34a/screen/5f68ca0bab272704c6b165a2',
      dependencies: ['d3'],
      hasTheme: true,
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.spiderMockData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  ngAfterViewInit() {
    this.render$.next()
  }
}
