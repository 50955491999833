import {
  LABEL_USAGE,
  LABEL_USAGE_PAGE_MODE,
} from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      <mc-application-menu
        [config]="appConfig"
        [applications]="userProfile">
      </mc-application-menu>
    `,
  },
  {
    label: 'Labeled',
    description: '',
    content: `
      <mc-application-menu
        [config]="appConfig"
        [label]="APPS"
        [applications]="userProfile">
      </mc-application-menu>
    `,
  },
  {
    label: LABEL_USAGE_PAGE_MODE,
    description: '',
    content: `
      <mc-application-menu
        [config]="appConfig"
        [applications]="userProfile"
        [pageMode]="true">
      </mc-application-menu>
    `,
  },
]
