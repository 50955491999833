import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class MatchItemMobilePageComponent {
        matchInfoData = matchData['default']
        MatchItemMobileType = MatchItemMobileType

        constructor() { }
      }
    `,
  },
  {
    label: '',
    description: 'Full',
    content: `
     <mc-match-item-mobile
      [match]="matchInfoData"
      [type]="MatchItemMobileType.Full">
     </mc-match-item-mobile>
    `,
  },
  {
    label: '',
    description: 'Listed',
    content: `
      <mc-match-item-mobile
        [match]="matchInfoData"
        [type]="MatchItemMobileType.Listed">
      </mc-match-item-mobile>
    `,
  },
  {
    label: '',
    description: 'Compressed',
    content: `
      <mc-match-item-mobile
        [match]="matchInfoData"
        [type]="MatchItemMobileType.Compressed">
      </mc-match-item-mobile>
    `,
  },
  {
    label: '',
    description: 'FullListed',
    content: `
      <mc-match-item-mobile
        [match]="matchInfoData"
        [type]="MatchItemMobileType.FullListed">
      </mc-match-item-mobile>
    `,
  },
]
