import { Routes } from '@angular/router'

import { MODULE_DOC  } from '@core/constants/documentation-data.constants'
import { AppSection } from '@core/models/app.constants'
import {
  ApplicationMenuPageComponent,
  BoxPageComponent,
  ButtonsPageComponent,
  ChipPageComponent,
  CollapsePageComponent,
  CorePageComponent,
  ErrorFeedbackPageComponent,
  ErrorPageComponent,
  FallbackPageComponent,
  FieldDiagramPageComponent,
  FieldPageComponent,
  HeatMapPageComponent,
  ListPageComponent,
  MainPageComponent,
  MatchItemMobilePageComponent,
  MatchItemPageComponent,
  MatchStatePageComponent,
  MediaItemPageComponent,
  MultiLineDiagramPageComponent,
  OverlayPageComponent,
  OverlayPanelPageComponent,
  PassMatrixPageComponent,
  RatingPageComponent,
  SharedPageComponent,
  SpiderChartPageComponent,
  TacticalDistancesPageComponent,
  TeamBadgePageComponent,
  TeamSelectPageComponent,
  TeamTrendPageComponent,
  TeamTrendTablePageComponent,
  TimelinePageComponent,
  TitlePageComponent,
} from '@pages/components'

const COMPONENTS = {
  FieldComponent: FieldPageComponent,
  FieldDiagramComponent: FieldDiagramPageComponent,
  TacticalDistancesComponent: TacticalDistancesPageComponent,
  TeamTrendTableComponent: TeamTrendTablePageComponent,
  HeatMapComponent: HeatMapPageComponent,
  PassMatrixComponent: PassMatrixPageComponent,
  MultilineDiagramComponent: MultiLineDiagramPageComponent,
  TimelineComponent: TimelinePageComponent,
  RatingComponent: RatingPageComponent,
  ButtonComponent: ButtonsPageComponent,
  BoxComponent: BoxPageComponent,
  OverlayComponent: OverlayPageComponent,
  OverlayPanelComponent: OverlayPanelPageComponent,
  ApplicationMenuComponent: ApplicationMenuPageComponent,
  ErrorPageComponent,
  MediaItemComponent: MediaItemPageComponent,
  MatchItemComponent: MatchItemPageComponent,
  MatchStateComponent: MatchStatePageComponent,
  ChipComponent: ChipPageComponent,
  TeamTrendComponent: TeamTrendPageComponent,
  ListComponent: ListPageComponent,
  TitleComponent: TitlePageComponent,
  MatchItemMobileComponent: MatchItemMobilePageComponent,
  SpiderChartComponent: SpiderChartPageComponent,
  CollapseComponent: CollapsePageComponent,
  TeamBadgeComponent: TeamBadgePageComponent,
  TeamSelectComponent: TeamSelectPageComponent,
  ErrorFeedbackComponent: ErrorFeedbackPageComponent,
}

const setRoutes = (type: AppSection, component?) => MODULE_DOC[type].elements.map((element) => ({
  path: element.name,
  component: component ? component : COMPONENTS[element.name],
  data: { type, element },
}))

export const MainRoutes: Routes = [
  { path: '', redirectTo: `${AppSection.Framework}/animations/config`, pathMatch: 'full' },
  { path: AppSection.Framework, redirectTo: `${AppSection.Framework}/animations/config`, pathMatch: 'full' },
  { path: AppSection.Core, redirectTo: `${AppSection.Core}/${MODULE_DOC[AppSection.Core].elements[0].name}`, pathMatch: 'full' },
  { path: AppSection.Components, redirectTo: `${AppSection.Components}/${MODULE_DOC[AppSection.Components].elements[0].name}`, pathMatch: 'full' },
  { path: AppSection.ChartComponents, redirectTo: `${AppSection.ChartComponents}/${MODULE_DOC[AppSection.ChartComponents].elements[0].name}`, pathMatch: 'full' },
  { path: AppSection.Directives, redirectTo: `${AppSection.Directives}/${MODULE_DOC[AppSection.Directives].elements[0].name}`, pathMatch: 'full' },
  { path: AppSection.Pipes, redirectTo: `${AppSection.Pipes}/${MODULE_DOC[AppSection.Pipes].elements[0].name}`, pathMatch: 'full' },
  { path: AppSection.Services, redirectTo: `${AppSection.Services}/${MODULE_DOC[AppSection.Services].elements[0].name}`, pathMatch: 'full' },
  { path: AppSection.Utils, redirectTo: `${AppSection.Utils}/${MODULE_DOC[AppSection.Utils].elements[0].name}`, pathMatch: 'full' },
  {
    path: ':section/:type',
    component: MainPageComponent,
    children: [
      { path: ':id', component: SharedPageComponent },
    ],
  },
  { path: 'fallback', component: FallbackPageComponent },
]

MainRoutes.splice(9, 0, {
  path: ':section',
  component: MainPageComponent,
  children: [
    ...setRoutes(AppSection.Core, CorePageComponent),
    ...setRoutes(AppSection.Components),
    ...setRoutes(AppSection.ChartComponents),
    ...setRoutes(AppSection.Directives, CorePageComponent),
    ...setRoutes(AppSection.Pipes, CorePageComponent),
    ...setRoutes(AppSection.Services, CorePageComponent),
    ...setRoutes(AppSection.Utils, CorePageComponent),
  ],
})
