<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="c-timeline__page-wrapper">
      <mc-button
        *ngFor="let index of dataIndexArr"
        [text]="'Data ' + index"
        [class.is-disabled]="selectedIndex === index"
        (click)="selectedIndex = index">
      </mc-button>
    </div>
    <div class="c-timeline__page-wrapper">
      <mc-button
        *ngFor="let isPeriodSeparated of [true, false]"
        [text]="(isPeriodSeparated ? 'Show' : 'Hide') + ' period'"
        [class.is-disabled]="showPeriods !== isPeriodSeparated"
        (click)="showPeriods = !isPeriodSeparated">
      </mc-button>
    </div>
    <ng-container *ngIf="((TIMELINE_DATA[selectedIndex] | async) || {}) as timelineData">
      <div class="c-timeline__page-wrapper">
        <mc-button (click)="getAllEvents(timeline)" text="Get Timeline Events"></mc-button>
        <mc-button (click)="lang = (lang === 'es') ? 'en' : 'es'" text="Change Lang"></mc-button>
        <mc-button (click)="manageEvent(timelineData, 'add')" text="Add event"></mc-button>
        <mc-button (click)="manageEvent(timelineData, 'remove')" text="Remove event"></mc-button>
        <mc-button (click)="manageEvent(timelineData, 'disable')" text="Disable event"></mc-button>
        <mc-button (click)="cameraType = cameraType === 'TAC' ? 'PAN' : 'TAC'" text="Change camera type"></mc-button>
        <label>SmallUntil</label><input [(ngModel)]="smallUntil">
      </div>
      <p>El componente Timeline muestra los hitos de un partido minuto a minuto: goles, penaltis, faltas...</p>
      <div class="u-background-color--grey--80 u-padding--xl" [style.width.%]="60" style="position: relative;">
      <mc-timeline id="test"
                   [data]="timelineData?.data"
                   [separatePeriods]="!showPeriods"
                   [smallUntil]="smallUntil || smallUntilDefault"
                   [disableConfig]="disableConfig(cameraType)"
                   [eventTranslations]="eventTranslations[lang]"
                   [periodTranslations]="periodTranslations[lang]"
                   (onEventsLoaded)="onEventsLoaded($event)"
                   (onEventClick)="onEventClick($event)"
                   (onPeriodClick)="onPeriodClick($event)" #timeline></mc-timeline>
      </div>
      <div class="u-background-color--black u-padding--xl" [style.width.%]="60" style="position: relative;">
        <mc-timeline id="test-1" style="width:100%;display: inline-block;"
                     [data]="timelineData?.data"
                     [separatePeriods]="!showPeriods"
                     [smallUntil]="smallUntil || smallUntilDefault"
                     [eventTranslations]="eventTranslations[lang]"
                     [periodTranslations]="periodTranslations[lang]"
                     [isMobile]="true"
                     (onPeriodClick)="onPeriodClick($event)"
                     (onEventClick)="onEventClick($event)"></mc-timeline>
      </div>
    </ng-container>
  </ng-template>
</app-overview-template>
