<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-field-full-size p-col-12 p-md-7">
      <div class="p-col-4">
        <mc-team-badge [team]="teamBadgeMockData['example']"></mc-team-badge>
        <mc-team-badge [team]="teamBadgeMockData['exampleNull']"></mc-team-badge>
      </div>
    </div>
  </ng-template>
</app-overview-template>
