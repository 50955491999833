import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ConnectionCoordinateType, FieldDiagramModifier, FieldDirection } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './field-diagram-page.constants'
import * as playerPositioningFinal from './player-positioning-final.json'
import * as playerPositioningStandard from './player-positioning-standard.json'

@Component({
  selector: 'app-field-diagram-page',
  templateUrl: './field-diagram-page.component.html',
  styleUrls: ['./field-diagram-page.component.scss'],
})
export class FieldDiagramPageComponent implements OnInit {

  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  playerPositioningStandardHome: object[]
  playerPositioningStandardAway: object[]
  playerPositioningFinalHome: object[]
  playerPositioningFinalAway: object[]
  fieldDiagramModifier = FieldDiagramModifier
  connectionCoordinateType = ConnectionCoordinateType
  fieldDiagramArrowDirection = FieldDirection
  selectedConnectionTypeA = ConnectionCoordinateType.Target

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.playerPositioningStandardHome = this.mapData(playerPositioningStandard['default'].data.home.formations)
    this.playerPositioningStandardAway = this.mapData(playerPositioningStandard['default'].data.away.formations)
    this.playerPositioningFinalHome = this.mapData(playerPositioningFinal['default'].data.home.formations)
    this.playerPositioningFinalAway = this.mapData(playerPositioningFinal['default'].data.away.formations)

    this.overviewDevData = setOverviewDevData({
      type: AppSection.ChartComponents,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCIU/Fluxograma+de+Pases+v3.0',
      visualsUrl: 'https://zpl.io/aMK08Zz',
      hasTheme: true,
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, playerPositioningStandard['default'])
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  mapData(data) {
    return data.map((item) => ({
      title: item.title,
      positioningType: item.type,
      values: item.values,
      connections: item.connections,
    }))
  }

  changeFieldDiagramA(connection: ConnectionCoordinateType.Target | ConnectionCoordinateType.Origin) {
    this.selectedConnectionTypeA = connection
  }
}
