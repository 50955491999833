<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <p>Example of team select with <strong>Valencia CF</strong> already selected</p>
    <p>The modal appearing after clicking a team it's just an example, not part of the component</p>
    <div class="p-team-select__container">
      <mc-team-select
        [teams]="teamSelectMockData"
        [selectedTeam]="demoSelectedTeam"
        (onTeamClicked)="setSelectedTeam($event)">
      </mc-team-select>
    </div>
  </ng-template>
</app-overview-template>

<mc-overlay
  [type]="OverlayType.Modal"
  [title]="modalTitle" #overlay>
  <div class="p-team-select__overlay" overlay-template="body">
    <h2>{{ demoSelectedTeam.name }}</h2>
    <img class="mc-team-badge__image"
         appDefaultImage
         [srcUrl]="(demoSelectedTeam || {}).portraitLogo"
         alt="">
  </div>
  <div overlay-template="footer">
    <div class="c-button-group c-button-group--separated">
      <mc-button text="Close" (click)="overlay.close()"></mc-button>
    </div>
  </div>
</mc-overlay>
