import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: 'Use the parseMatrix() utility, to transform the string into an array.',
    content: `
      export class TacticalDistancesExampleComponent implements OnInit {
        this.tacticalDistanceHome = this.mapData(tacticalDistance['default'].data.playingFields)
        constructor() {}
      }
    `,
  },
  {
    content: `
      <mc-tactical-distances [isHome]="false" [data]="tacticalDistanceHome"></mc-tactical-distances>
    `,
  },
]
