import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      import { MediaItemModifier } from '@mediacoach-ui-library/global'

      export class MediaItemExampleComponent {
        mediaItemData = {
          media: "2019_Valencia_CF_Small2x_Portrait.png",
          text: "Valencia"
        }
        mediaItemModifier = MediaItemModifier
        constructor() { }
      }
    `,
  },
  {
    label: '',
    description: 'Default',
    content: `
      <mc-media-item [data]="mediaItemData"></mc-media-item>
    `,
  },
  {
    label: '',
    description: 'Inline / Reverse',
    content: `
      <mc-media-item
        [data]="mediaItemData"
        [modifiers]="[mediaItemModifier.Inline, mediaItemModifier.Reverse]">
      </mc-media-item>
    `,
  },
  {
    label: '',
    description: 'Banner / SizeLg ⁄ Dark / Inline',
    content: `
      <mc-media-item
        [data]="mediaItemData"
        [modifiers]="[
          mediaItemModifier.Banner,
          mediaItemModifier.SizeLg,
          mediaItemModifier.Dark,
          mediaItemModifier.Inline
        ]">
      </mc-media-item>
    `,
  },
]
