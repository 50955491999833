import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      <mc-error-page></mc-error-page>
    `,
  },
]
