import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { of } from 'rxjs'
import { delay, map, shareReplay, startWith } from 'rxjs/operators'

import { ActivatedRoute } from '@angular/router'
import {
  EventType,
  Period,
  PeriodGroup,
  RandomNumber,
  TEAMS,
  TimelineComponent, TimelineEvent,
} from '@mediacoach-ui-library/global'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './timeline-page.constants'
import * as timelineData from './timeline.mock.json'

const TIMELINE_DATA = timelineData['default'].map((data) => of(data).pipe(
  startWith({
    home: {logo: 'https://statics-test01.mediacoach.es/mcpfilestest0…614441367&n=2019_Valencia_CF_Small2x_Portrait.png'},
    away: {logo: 'https://statics-test01.mediacoach.es/mcpfilestest0…Q&e=1614441367&n=2019_Getafe_Small2x_Portrait.png'},
  }),
  delay(200),
  map((data) => ({data})),
  shareReplay(1),
))

@Component({
  selector: 'app-timeline-page',
  templateUrl: './timeline-page.component.html',
  styleUrls: ['./timeline-page.component.scss'],
})
export class TimelinePageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  id = 100000
  lang = 'en'
  smallUntil
  smallUntilDefault = {
    [PeriodGroup.Normal]: 800,
    [PeriodGroup.Extra]: 1200,
  }
  dataIndexArr = Array(TIMELINE_DATA.length).fill(1).map((v, i) => i)
  selectedIndex = 0
  timeline: TimelineComponent
  showPeriods = false
  cameraType = 'TAC'
  eventTranslations = {
    en: {
      [EventType.Shot]: 'Remates a puerta',
      [EventType.YellowCard]: 'Tarjetas Amarilla',
      [EventType.DoubleYellowCard]: 'Doble Tarjeta Amarilla',
      [EventType.RedCard]: 'Tarjeta Roja',
      [EventType.Goal]: 'Gooool',
      [EventType.MissedPenal]: 'Penalti Fallado',
      [EventType.OwnGoal]: 'Gol en Propia Puerta',
    },
    es: {
      [EventType.Shot]: 'Shot',
      [EventType.YellowCard]: 'Yellow Card',
      [EventType.DoubleYellowCard]: 'Double Yellow Card',
      [EventType.RedCard]: 'Red Card',
      [EventType.Goal]: 'Goooal',
      [EventType.MissedPenal]: 'Missed Penalty',
      [EventType.OwnGoal]: 'Own Goal',
    },
  }

  periodTranslations = {
    en: {
      [Period.First]: '1st Part',
      [Period.Second]: '2nd Part',
      [Period.ExtraFirst]: '1st Extra Time',
      [Period.ExtraSecond]: '2nd Extra Time',
      [Period.Penalties]: 'Penalties',
      [Period.End]: 'Finished',
    },
    es: {
      [Period.First]: '1ª Parte',
      [Period.Second]: '2ª Parte',
      [Period.ExtraFirst]: '1ª Prorroga',
      [Period.ExtraSecond]: '2ª Prorroga',
      [Period.Penalties]: 'Penaltis',
      [Period.End]: 'Fin',
    },

  }

  TIMELINE_DATA = TIMELINE_DATA

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) {}
  disableConfig = (cameraType) => (event) => !(event.timeItems || {})[cameraType]

  ngOnInit() {
    window['TIMELINE_DATA'] = TIMELINE_DATA

    this.overviewDevData = setOverviewDevData({
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCIU/Timeline+de+Eventos+Partido+v4.0',
      visualsUrl: 'https://zpl.io/aByD9Dm',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, timelineData['default'])
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  getAllEvents(timeline: TimelineComponent) {
    console.info(timeline.getAllEvents())
  }

  manageEvent(timelineData, opc = 'add') {
    const _timelineData = JSON.parse(JSON.stringify(timelineData.data))
    const team = TEAMS[RandomNumber(TEAMS.length - 1)]
    const timelinePeriods = Object.keys(_timelineData.periods)
    const periods = Object.keys(_timelineData[team]).filter((period) => timelinePeriods.includes(period))
    const period = periods[RandomNumber(periods.length - 1)]

    if (team && period) {
      const events = _timelineData[team][period]
      if (opc === 'remove') {
        events.splice(RandomNumber(events.length - 1), 1)
      } else if (opc === 'add') {
        const event = {
          id: this.id++,
          player: 11,
          playerFirstname: 'Junior Wakalible',
          playerSurname: 'Lago',
          playerPhotoUrl: 'https://statics-pre.mediacoach.es/mcpfilespre/statics/players/20200310/39f3d8c1-ab83-d13a-5a8e-54612a4c8f73?st=lwrsotvbh-f0bQ-JZFdEZg&e=1613142609&n=2019_Junior_Wakalible_Lago_Medium_Portrait.jpg',
          eventType: 'Shot',
          minute: RandomNumber(_timelineData.periods[period].start, _timelineData.periods[period].end),
        }
        events.push(event)
      } else {
        events[RandomNumber(events.length - 1)].disabled = true
      }

      timelineData.data = _timelineData
    }
  }

  onEventsLoaded(events: TimelineEvent[]) {
    console.info(events)
  }
  onEventClick(event: TimelineEvent) {
    console.info(event)
  }
  onPeriodClick(data) {
    console.info(data)
  }
}
