<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">With Title</h5>
        <mc-box title="Box Title"></mc-box>
      </div>
      <div class="p-col-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">hasDecoration / Modifier Bottom, Gradient and Horizontal</h5>
        <mc-box
          title="Box Title"
          [hasDecoration]="true"
          [modifers]="[boxModifier.Bottom, boxModifier.Horizontal, boxModifier.Gradient]">
        </mc-box>
      </div>
      <div class="p-col-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">hasDecoration</h5>
        <mc-box title="Box Title" [hasDecoration]="true"></mc-box>
      </div>
      <div class="p-col-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">hasDecoration / Modifier Away</h5>
        <mc-box
          title="Box Title"
          [hasDecoration]="true"
          [modifers]="[boxModifier.Away]">
        </mc-box>
      </div>
      <div class="p-col-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">With Content</h5>
        <mc-box
          title="Box Title"
          [hasDecoration]="true"
          [modifers]="[boxModifier.Away]">
          <div class="u-margin-bottom--lg">
            <mc-chip [data]="{ label: 'MTR_COMMON_ENDED' }"></mc-chip>
          </div>
          <mc-button text="Button"></mc-button>
        </mc-box>
      </div>
    </div>

  </ng-template>
</app-overview-template>
