import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { untilDestroyed } from 'ngx-take-until-destroy'

import { GET_PLAYERS_FORMATTED, List, ListType, PlayerListItem, SafeObjectData } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './list-page.constants'
import * as listData from './list.mock.json'

@Component({
  selector: 'app-list',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent implements OnInit, OnDestroy {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  listMockData = listData['default']
  players: List<PlayerListItem> = {} as List<PlayerListItem>

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) {
  }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCPF/List',
      visualsUrl: 'https://app.zeplin.io/project/58e60ec50c7ed3178713d34a/screen/5f68ca0bab272704c6b165a2',
      hasTheme: true,
    })

    this._route.data.pipe(
      untilDestroyed(this),
    ).subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.listMockData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })

    const playerGroups = SafeObjectData(this.listMockData, 'squad.players', []).reduce((obj, item) => ({
      ...obj,
      [item.playerPositionKey]: [...obj[item.playerPositionKey] || [], item],
    }), {})

    this.players = {
      type: ListType.Players,
      groups: Object.keys(playerGroups).map((key) => ({
        title: key,
        items: GET_PLAYERS_FORMATTED(
          playerGroups[key],
          () => true,
          () => 'link',
        ),
      })),
    }
  }

  ngOnDestroy() {
  }
}
