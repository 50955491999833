
import packageJson  from '../../package.json'

export const COMMON_CONFIG = {
  firebase: {
    apiKey: "AIzaSyBcHRxQWXQtmUVcWeb9JdN7t4FrYFz9Ays",
    authDomain: "mediacoach-ui-components.firebaseapp.com",
    projectId: "mediacoach-ui-components",
    storageBucket: "mediacoach-ui-components.appspot.com",
    messagingSenderId: "514703877948",
    appId: "1:514703877948:web:cc73a7c1507cc31292e039"
  },
  buildVersion: packageJson?.version
}
