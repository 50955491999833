import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ChipData, ChipStates } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './chip-page.constants'

@Component({
  selector: 'app-chip-page',
  templateUrl: './chip-page.component.html',
  styleUrls: ['./chip-page.component.scss'],
})
export class ChipPageComponent implements OnInit {

  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  chipData: ChipData
  chipDataActive: ChipData
  chipDataDeactivated: ChipData

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      visualsUrl: 'https://zpl.io/25pddmr',
    })

    this.chipData = { label: 'MTR_COMMON_ENDED' }
    this.chipDataActive = { label: 'MTR_COMMON_ACTIVE', state: ChipStates.Active }
    this.chipDataDeactivated = { label: 'MTR_COMMON_DEACTIVATED', state: ChipStates.Deactivated }

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.chipDataActive)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

}
