import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'

import { MenuModule } from 'primeng/menu'
import { SidebarModule } from 'primeng/sidebar'
import { TabMenuModule } from 'primeng/tabmenu'

import { SharedModule } from '@shared/shared.module'

import { ChartComponentsModule } from '@mediacoach-ui-library/global'
import {
  ApplicationMenuPageComponent,
  BoxPageComponent,
  ButtonsPageComponent,
  ChipPageComponent,
  CollapsePageComponent,
  CorePageComponent,
  ErrorFeedbackPageComponent,
  ErrorPageComponent,
  FallbackPageComponent,
  FieldDiagramPageComponent,
  FieldPageComponent,
  HeatMapPageComponent,
  ListPageComponent,
  MainPageComponent,
  MatchItemMobilePageComponent,
  MatchItemPageComponent,
  MatchStatePageComponent,
  MediaItemPageComponent,
  MultiLineDiagramPageComponent,
  OverlayPageComponent,
  OverlayPanelPageComponent,
  PassMatrixPageComponent,
  RatingPageComponent,
  SharedPageComponent,
  SpiderChartPageComponent,
  TacticalDistancesPageComponent,
  TeamBadgePageComponent,
  TeamSelectPageComponent,
  TeamTrendPageComponent,
  TeamTrendTablePageComponent,
  TimelinePageComponent,
  TitlePageComponent,
} from './components'

const EXPORTED_COMPONENTS = [
  MainPageComponent,
  FallbackPageComponent,
  FieldPageComponent,
  FieldDiagramPageComponent,
  HeatMapPageComponent,
  TacticalDistancesPageComponent,
  SharedPageComponent,
  ButtonsPageComponent,
  BoxPageComponent,
  RatingPageComponent,
  OverlayPageComponent,
  OverlayPanelPageComponent,
  PassMatrixPageComponent,
  TeamTrendTablePageComponent,
  MultiLineDiagramPageComponent,
  TimelinePageComponent,
  ApplicationMenuPageComponent,
  ErrorPageComponent,
  CorePageComponent,
  MediaItemPageComponent,
  MatchItemPageComponent,
  MatchStatePageComponent,
  ChipPageComponent,
  TeamTrendPageComponent,
  ListPageComponent,
  TitlePageComponent,
  MatchItemMobilePageComponent,
  SpiderChartPageComponent,
  CollapsePageComponent,
  TeamBadgePageComponent,
  TeamSelectPageComponent,
  ErrorFeedbackPageComponent,
]

@NgModule({
  declarations: EXPORTED_COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    ChartComponentsModule,
    MenuModule,
    TabMenuModule,
    SidebarModule,
    FormsModule,
  ],
  exports: EXPORTED_COMPONENTS,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule { }
