import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApplicationMenuConfig, ApplicationName, EnvType, OverlayComponent, UserProfileType } from '@mediacoach-ui-library/global'
import { timer } from 'rxjs'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { OverlayType } from '../overlay-page/overlay.model'
import { DEV_TABS } from './application-menu-page.constants'

@Component({
  selector: 'app-application-menu-page',
  templateUrl: './application-menu-page.component.html',
  styleUrls: ['./application-menu-page.component.scss'],
})
export class ApplicationMenuPageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  @ViewChild('overlay', { static: false }) overlay: OverlayComponent
  overviewData: OverviewData
  overviewDevData: OverviewDevData

  public userProfile = [
    { id: UserProfileType.PortalUserProfile },
    { id: UserProfileType.DesktopUserProfile },
    { id: UserProfileType.BackOfficeUserProfile },
    { id: UserProfileType.WimuUserProfile },
    { id: UserProfileType.LiveUserProfile },
    { id: UserProfileType.FootovisionUserProfile },
  ]

  public appConfig: ApplicationMenuConfig = {
    environment: EnvType.Test01,
    application: ApplicationName.Portal,
    blacklist: [],
  }

  public OverlayType = OverlayType
  public modalTitle = 'Formulario de petición'

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _route: ActivatedRoute,
    private _dataService: SharedDataService,
  ) { }

  ngOnInit() {

    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      visualsUrl: 'https://zpl.io/agOMG01',
      hasTheme: true,
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.userProfile)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  // Deeplink falback
  gotoDeepLink(app) {
    timer(2000).subscribe(() => {
      if (this.document.hasFocus()) {
        this.modalTitle = 'Descargar Mediacoach Desktop'
        this.overlay.open()
      }
    })
    window.open(app.link, '_self')
  }
}
