<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-margin-bottom--xl">
      <div class="p-col-12 p-md-6">
        <mc-field></mc-field>
      </div>
      <div class="p-col-12 p-md-6">
        <mc-field [isColored]="true" [direction]="arrowDirection"></mc-field>
      </div>
    </div>
  </ng-template>
</app-overview-template>
