export interface DocumentationData {
  pipes: any[]
  interfaces: Interface[]
  injectables: Injectable[]
  classes: any[]
  directives: any[]
  components: DocumentationDataComponent[]
  modules: Module[]
  miscellaneous: Miscellaneous
  routes: any[]
  coverage: Coverage
}

export interface Coverage {
  count: number
  status: string
  files: File[]
}

export interface File {
  filePath: string
  type: string
  linktype: string
  name: string
  coveragePercent: number
  coverageCount: string
  status: string
  linksubtype?: string
}

export interface Miscellaneous {
  variables: Variable[]
  functions: any[]
  typealiases: any[]
  enumerations: Enumeration[]
  groupedVariables: GroupedVariables
  groupedFunctions: GroupedFunctions
  groupedEnumerations: GroupedEnumerations
  groupedTypeAliases: GroupedFunctions
}

export interface GroupedEnumerations {
  'libs/chart-components/src/lib/timeline/timeline.models.ts': Enumeration[]
  'libs/chart-components/src/lib/field-diagram/field-diagram.model.ts': Enumeration[]
  'libs/chart-components/src/lib/team-trend/team-trend.model.ts': Enumeration[]
  'libs/chart-components/src/lib/heat-map/heat-map.model.ts': Enumeration[]
}

export interface GroupedFunctions {
}

export interface GroupedVariables {
  'libs/chart-components/src/lib/multiline-diagram/multiline-diagram.constants.ts': Variable[]
  'libs/chart-components/src/lib/timeline/timeline.constants.ts': Variable[]
  'libs/chart-components/src/lib/heat-map/heat-map.constants.ts': Variable[]
  'libs/chart-components/src/lib/heat-map/heat-map.component.ts': Variable[]
  'libs/chart-components/src/lib/field-diagram/field-diagram.constants.ts': Variable[]
  'libs/chart-components/src/lib/tactical-distances/tactical-distances.model.ts': Variable[]
  'libs/chart-components/src/lib/team-trend/team-trend.component.ts': Variable[]
}

export interface Enumeration {
  name: string
  childs: Child2[]
  ctype: string
  subtype: string
  description: string
  file: string
}

export interface Child2 {
  name: string
  value: string
}

export interface Variable {
  name: string
  ctype: string
  subtype: string
  file: string
  type: string
  defaultValue: string
}

export interface Module {
  name: string
  children: Child[]
}

export interface Child {
  type: string
  elements: Element[]
}

export interface Element {
  name: string
}

export interface DocumentationDataComponent {
  name: string
  id: string
  file: string
  encapsulation: any[]
  entryComponents: any[]
  inputs: any[]
  outputs: any[]
  providers: any[]
  selector: string
  styleUrls: string[]
  styles: any[]
  templateUrl: string[]
  viewProviders: any[]
  inputsClass: InputsClass[]
  outputsClass: OutputsClass[]
  propertiesClass: PropertiesType[]
  methodsClass: MethodsClass[]
  methods: MethodsClass[]
  hostBindings: any[]
  hostListeners: any[]
  description: string
  rawdescription: string
  type: string
  sourceCode: string
  assetsDirs: any[]
  styleUrlsData: StyleUrlsDatum[]
  stylesData: string
  constructorObj: ConstructorObj2
  implements: string[]
  templateData: string
  accessors?: Accessors
}

export interface Accessors {
  heatMapData?: HeatMapData
  teamTrendTexts?: HeatMapData
  allEvents?: HeatMapData
  eventGroups?: HeatMapData
  timelineConfig?: HeatMapData
  eventTranslations?: HeatMapData
  periodTranslations?: HeatMapData
  data?: HeatMapData
  separatePeriods?: HeatMapData
}

export interface HeatMapData {
  name: string
  setSignature: SetSignature
  getSignature: GetSignature
}

export interface GetSignature {
  name: string
  type: string
  returnType: string
  line: number
}

export interface SetSignature {
  name: string
  type: string
  args: Arg[]
  returnType: string
  line: number
  jsdoctags: Jsdoctag[]
}

export interface ConstructorObj2 {
  name: string
  description: string
  args: Arg[]
  line: number
  jsdoctags?: Jsdoctag[]
}

export interface StyleUrlsDatum {
  data: string
  styleUrl: string
}

export interface MethodsClass {
  name: string
  args: Arg2[][]
  optional: boolean
  returnType: string
  typeParameters: any[]
  line: number
  jsdoctags?: Jsdoctag2[]
  description?: string
  modifierKind?: number[]
}

export interface Jsdoctag2 {
  name: Name | string | string
  type: string
  tagName: TagName2
  comment?: string
  typeExpression?: TypeExpression
  defaultValue?: string
}

export interface TypeExpression {
  pos: number
  end: number
  flags: number
  kind: number
  type: Type
}

export interface Type {
  pos: number
  end: number
  flags: number
  kind: number
  typeName: Name
}

export interface TagName2 {
  text?: string
  pos?: number
  end?: number
  flags?: number
  escapedText?: string
}

export interface Name {
  pos: number
  end: number
  flags: number
  escapedText: string
}

export interface Arg2 {
  name: string
  type: string
  defaultValue?: string
}

export interface PropertiesClass3 {
  name: string
  defaultValue: string
  type: string
  optional: boolean
  description: string
  line: number
}

export interface PropertiesClass2 {
  name: string
  type: string
  optional: boolean
  description: string
  line: number
  defaultValue?: string
  decorators?: Decorator[]
}

export interface PropertiesClass {
  name: string
  type: string
  optional: boolean
  description: string
  line: number
  modifierKind?: number[]
  defaultValue?: string
  decorators?: Decorator[]
}

export type PropertiesType = (PropertiesClass | PropertiesClass2 | PropertiesClass3)

export interface Decorator {
  name: string
  stringifiedArguments: string
}

export interface OutputsClass {
  name: string
  defaultValue: string
  line: number
  type: string
}

export interface InputsClass {
  name: string
  defaultValue?: string
  line: number
  type?: string
}

export interface Injectable {
  name: string
  id: string
  file: string
  properties: Property2[]
  methods: Method[]
  description: string
  sourceCode: string
  constructorObj: ConstructorObj
  type: string
}

export interface ConstructorObj {
  name: string
  description: string
  args: any[]
  line: number
}

export interface Method {
  name: string
  args: Arg[]
  optional: boolean
  returnType: string
  typeParameters: any[]
  line: number
  jsdoctags: Jsdoctag[]
}

export interface Jsdoctag {
  name: string
  type: string
  tagName: TagName
}

export interface TagName {
  text: string
}

export interface Property2 {
  name: string
  defaultValue: string
  type: string
  optional: boolean
  description: string
  line: number
  modifierKind: number[]
}

export interface Interface {
  name: string
  id: string
  file: string
  type: string
  sourceCode: string
  properties: Array<Property | Properties2 | Properties3>
  indexSignatures: IndexSignature[]
  kind: number
  methods: any[]
}

export interface IndexSignature {
  id: string
  args: Arg[]
  returnType: string
  line: number
}

export interface Arg {
  name: string
  type: string
}

export interface Properties3 {
  type: string
  optional: boolean
  description: string
  line: number
}

export interface Properties2 {
  name: string
  type: string
  optional: boolean
  description: string
  line: number
}

export interface Property {
  name?: string
  type: string
  optional: boolean
  description: string
  line: number
}

export enum DocumentationDataType {
  Inputs = 'Inputs',
  Output = 'Output',
  HostBindings = 'hostBindings',
  Properties = 'Properties',
  Methods = 'Methods',
  Interfaces = 'Interfaces',
  Enumerations = 'Enumerations',
  Constants = 'Constants',
}
