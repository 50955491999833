import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      <mc-overlay-panel #modal>
        <div>
          <p>Contenido del overlay panel</p>
        </div>
     </mc-overlay-panel>
    `,
  },
]
