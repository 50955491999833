import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    content: `
      <mc-field></mc-field>
    `,
  },
  {
    description: `
      You can also configure the direction of the field arrow (left, right ) and an if the field is colored.
    `,
    content: `
      <mc-field
        [isColored]="isColored"
        [direction]="arrowDirection">
      </mc-field>
    `,
  },
]
