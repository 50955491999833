import { Component, Input, Renderer2 } from '@angular/core'
import { AppTheme } from '@mediacoach-ui-library/global'
import { DocumentationDataType } from '@core/models/documentation-data.models'
import { OverlayType } from '@pages/components/overlay-page/overlay.model'
import { OverviewData, OverviewDevData } from './overview-data.model'

@Component({
  selector: 'app-overview-template',
  templateUrl: './overview-template.component.html',
  styleUrls: ['./overview-template.component.scss'],
})
export class OverviewTemplateComponent {
  @Input() public data: OverviewData
  @Input() public devData: OverviewDevData
  isDark: boolean
  DOC_TYPE = DocumentationDataType
  public OverlayType = OverlayType
  public codeButtons = [
    { label: 'Data Exemple', type: 'mockData' },
    { label: 'Template', type: 'template' },
    { label: 'Code', type: 'code' },
  ]
  public overlayData = ''

  constructor(private renderer: Renderer2) { }

  onLaunch(url) {
    window.open(url, '_blank')
  }

  setOverlayType(type) {
    this.overlayData = type
  }

  toggleTheme() {
    this.isDark = !this.isDark
    this.renderer[this.isDark ? 'addClass' : 'removeClass'](document.body, AppTheme.Dark)
  }
}
