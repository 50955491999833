<div class='mc-login' >
  <div class='mc-login__logo'>
    <img src='/assets/logos/logo-mediacoach.svg' alt='logo'>
    <h3>Mediacoach UI Components</h3>
    <span class="mc-login__logo-version">v{{buildVersion}}</span>
  </div>
  <div class='mc-login__form' [formGroup]='form' >
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"><i class="pi pi-user" style="line-height: 1.25;"></i></span>
      <input type="text" pInputText placeholder="Email*" formControlName="email">
    </div>
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"><i class="pi pi-key" style="line-height: 1.25;"></i></span>
      <input type="password" pInputText placeholder="Password*" formControlName="password">
    </div>

    <button pButton type="submit" label="Submit" [disabled]='form.invalid' (click)='login()'></button>
  </div>

</div>

