import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class BoxPageComponent implements OnInit {
        boxModifier = BoxModifier
        constructor() { }
        ngOnInit() { }
      }
    `,
  },
  {
    label: '',
    description: '',
    content: `
      <mc-box
        title="Box Title"
        [hasDecoration]="true"
        [modifers]="[
          boxModifier.Bottom,
          boxModifier.Horizontal,
          boxModifier.Gradient
        ]">

        <div class="u-margin-bottom--lg">
          <mc-chip [data]="{ label: 'MTR_COMMON_ENDED' }"></mc-chip>
        </div>
        <mc-button text="Button"></mc-button>
      </mc-box>
    `,
  },
]
