import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OverlayType } from '@mediacoach-ui-library/global'
import { MenuItem } from 'primeng/api'
import { MODULE_DOC } from '@core/constants/documentation-data.constants'
import { AppSection } from '@core/models/app.constants'

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  menuItems: MenuItem[]
  tabMenuItems: MenuItem[]
  pageTitle: string
  overlayType = OverlayType

  SECTIONS_CONFIG = {
    [AppSection.ChartComponents]: { name: 'Chart Component', replaceString: 'Component' },
    [AppSection.Components]: { name: 'Component', replaceString: 'Component' },
    [AppSection.Directives]: { name: 'Directives', replaceString: 'Directive' },
    [AppSection.Services]: { name: 'Services', replaceString: 'Service' },
    [AppSection.Pipes]: { name: 'Pipes', replaceString: 'Pipe' },
    [AppSection.Utils]: { name: 'Utils', replaceString: 'Util' },
    [AppSection.Core]: { name: 'Core', replaceString: '' },
  }

  constructor(
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this._route.params.subscribe((params) => {

      if (params['section'] === AppSection.Framework) {
        this.pageTitle = 'Framework'
        this.menuItems = MODULE_DOC[AppSection.Framework].map((section) => ({
          label: section.name,
          icon: 'pi pi-cog',
          routerLink: [`/${params['section']}`, section.name, section.items[0].name],
        }))
        this.tabMenuItems = MODULE_DOC[AppSection.Framework].find((section) => section.name === params['type']).items.map((section) => ({
          label: section.name,
          icon: 'pi pi-paperclip',
          routerLink: [section.name],
        }))
      } else {
        this.menuItems = this.setMenuItems(params['section'])
      }
    })
  }

  setMenuItems(section: AppSection) {
    this.pageTitle = this.SECTIONS_CONFIG[section].name
    const re = new RegExp(this.SECTIONS_CONFIG[section].replaceString, 'g')
    return MODULE_DOC[section].elements.map((element) => ({
      label: element.name.replace(re, ''),
      routerLink: [element.name],
    }))
  }
}
