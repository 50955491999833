import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class SpiderChartPageComponent {
        mockData = mockData['default']

        constructor() { }
      }
    `,
  },
  {
    label: '',
    description: 'No data',
    content: `
      <mc-spider-chart [data]="[]" [header]="spiderHeader"></mc-spider-chart>
    `,
  },
  {
    label: '',
    description: 'No values',
    content: `
      <mc-spider-chart [data]="spiderDataNoValues" [header]="spiderHeader"></mc-spider-chart>
    `,
  },
  {
    label: '',
    description: 'Complete example',
    content: `
      <mc-spider-chart
        [data]="spiderData"
        [header]="spiderHeader"
        [render$]="render$">

      </mc-spider-chart>
    `,
  },
  {
    label: '',
    description: 'Custom header',
    content: `
      <mc-spider-chart [data]="spiderData" [render$]="render$">
        <ng-template mcTemplate="header">
          <h3>Esto es una cabecera personalizada</h3>
        </ng-template>
      </mc-spider-chart>
    `,
  },
]

