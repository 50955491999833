import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MatchItemMobileType } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './match-item-mobile-page.constants'
import * as matchData from './match-item-mobile.mock.json'

@Component({
  selector: 'app-match-item-mobile-page',
  templateUrl: './match-item-mobile-page.component.html',
  styleUrls: ['./match-item-mobile-page.component.scss'],
})
export class MatchItemMobilePageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  matchInfoData = matchData['default']
  MatchItemMobileType = MatchItemMobileType

  constructor(
    private _route: ActivatedRoute,
    private _dataService: SharedDataService,
  ) { }

  ngOnInit() {

    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      visualsUrl: 'https://app.zeplin.io/project/58e60ec50c7ed3178713d34a/dashboard?sid=5f69b7cffde7020b029a88d5',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.matchInfoData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
