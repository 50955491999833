import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ButtonModifier, ButtonType } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './buttons-page.constants'

@Component({
  selector: 'app-buttons-page',
  templateUrl: './buttons-page.component.html',
  styleUrls: ['./buttons-page.component.scss'],
})
export class ButtonsPageComponent implements OnInit {
  ButtonType = ButtonType
  ButtonModifier = ButtonModifier

  overviewData: OverviewData
  overviewDevData: OverviewDevData
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      visualsUrl: 'https://zpl.io/VYp3ELM',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
