<div class="p-grid u-margin-bottom--lg">
  <div class="p-col-12 p-lg-9">
    <div class="u-font-family--primary u-color--primary">{{data?.mainType}}</div>
    <h1 class="u-font-family--secondary u-margin-bottom--sm">{{data?.mainTitle}}</h1>
    <h4 class="u-font-family--secondary u-margin-bottom--lg" [innerHTML]="data?.mainDescription | safe: 'html'"></h4>
    <div class="u-color--away u-margin-bottom--md">{{data?.file}}</div>
    <div *ngIf="devData?.mainDependencies">
      <p class="u-font-family--accent">Dependencies</p>
      <div class="doc-dependency u-margin-bottom--md">
        <span class="doc-dependency__item" *ngFor="let dependency of devData.mainDependencies">
          {{dependency}}
        </span>
      </div>
    </div>
  </div>
  <div class="p-col">
    <div class="p-grid p-justify-end">
      <ng-container *ngFor="let button of devData?.externalURLs">
        <div class="p-col-4 p-lg-5" *ngIf="button.destinationURL">
          <p-button
            styleClass="p-button-raised p-button-rounded"
            [label]="button.label"
            icon="pi pi-{{button.icon}}"
            (click)="onLaunch(button.destinationURL)"
            iconPos="left">
          </p-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="devData?.docTabs">
  <div class="p-grid u-margin-bottom--lg">
    <div class="p-col-12 p-lg-6 u-margin-bottom--lg">
      <div class="p-grid">
        <div class="p-col-4 p-lg-3">
          <p-button
            styleClass="p-button-raised p-button-primary"
            label="Toggle color mode"
            [disabled]="!devData.hasTheme"
            (click)="toggleTheme()">
          </p-button>
        </div>
        <ng-container *ngFor="let button of codeButtons">
          <div class="p-col-4 p-lg-3" *ngIf="data[button.type]">
            <p-button
              styleClass="p-button-raised p-button-primary"
              [label]="button.label"
              (click)="overlay.open(); setOverlayType(button.type)"
              iconPos="left">
            </p-button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <mc-overlay [type]="OverlayType.SidePanel" [title]="overlayData" #overlay>
    <div class="c-profile" overlay-template="body">
      <pre><code [highlight]="data[overlayData] || ''"></code></pre>
    </div>
    <div overlay-template="footer">
      <div class="c-button-group c-button-group--separated">
        <mc-button text="Close" (click)="overlay.close()"></mc-button>
      </div>
    </div>
  </mc-overlay>
</div>

<!-- COMPONENT Variant -->
<div *ngIf="devData?.voidTabs" class="u-margin-bottom--xl">
  <div *ngFor="let tab of devData?.voidTabs">
    <h3 class="u-margin-bottom--xl">{{tab?.label}}</h3>
    <div class="u-margin-bottom--xl">
      <ng-container [ngTemplateOutlet]="tab?.template" [ngTemplateOutletContext]="{ $implicit: tab }"></ng-container>
    </div>
    <!-- DEV Extension -->
    <div *ngIf="tab?.devTabs" class="dev-tab-group">
      <div *ngFor="let dev of tab?.devTabs">
        <h3 class="u-margin-bottom--xl">{{dev.label}}</h3>
        <div class="u-margin-bottom--xl">
          <pre><code [highlight]="dev?.content || ''"></code></pre>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DEV Extension -->
<div *ngIf="devData?.devTabs" class="dev-tab-group">
  <div *ngFor="let tab of devData?.devTabs">
    <h3 class="u-margin-bottom--lg">{{tab?.label}}</h3>
    <p class="u-margin-bottom--lg">{{tab?.description}}</p>
    <div class="u-margin-bottom--xl">
      <pre><code [highlight]="tab?.content || ''"></code></pre>
    </div>
  </div>
</div>

<!-- DOC Extension -->
<div *ngIf="devData?.docTabs" class="dev-tab-group">
  <div *ngFor="let doc of devData?.docTabs">
    <div *ngIf="doc?.content?.length" class="u-margin-bottom--xl">
      <h3 class="u-margin-bottom--lg">{{doc?.type}}</h3>
      <div [ngSwitch]="doc?.type">
        <p-table [value]="doc?.content">
          <ng-container *ngSwitchCase="DOC_TYPE.Methods">
            <ng-template pTemplate="header" let-input>
              <tr>
                <th>Name</th>
                <th>Parameters</th>
                <th>Returns</th>
                <th>Description</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-input>
              <tr>
                <td>{{input.name}}</td>
                <td>
                  <div *ngFor="let arg of input.args">
                    {{arg.name}}: <span class="u-color--away">{{arg.type}}</span>
                  </div>
                </td>
                <td>{{input.returnType}}</td>
                <td [innerHTML]="input.description ? input.description : '-' | safe: 'html'"></td>
              </tr>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="DOC_TYPE.Interfaces">
            <ng-template pTemplate="header" let-input>
              <tr>
                <th>Name</th>
                <th>Properties</th>
                <th>Description</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-input>
              <tr>
                <td>{{input.name}}</td>
                <td>
                  <div *ngFor="let arg of input.properties">
                    {{arg.name}}{{arg.optional ? '?' : ''}}: <span class="u-color--away">{{arg.type}}</span>
                  </div>
                </td>
                <td [innerHTML]="input.description ? input.description : '-' | safe: 'html'"></td>
              </tr>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="DOC_TYPE.Enumerations">
            <ng-template pTemplate="header" let-input>
              <tr>
                <th>Name</th>
                <th>Childs</th>
                <th>Description</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-input>
              <tr>
                <td>{{input.name}}</td>
                <td>
                  <div *ngFor="let arg of input.childs">
                    {{arg.name}} = <span class="u-color--away">{{arg.value}}</span>
                  </div>
                </td>
                <td [innerHTML]="input.description ? input.description : '-' | safe: 'html'"></td>
              </tr>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="DOC_TYPE.Constants">
            <ng-template pTemplate="header" let-input>
              <tr>
                <th>Name</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-input>
              <tr>
                <td>{{input.name}}</td>
                <td>
                  <pre><code [highlight]="input.defaultValue"></code></pre>
                </td>
                <td [innerHTML]="input.description ? input.description : '-' | safe: 'html'"></td>
              </tr>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-template pTemplate="header" let-input>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-input>
              <tr>
                <td>{{input.name}}</td>
                <td>{{input.type}}</td>
                <td>{{input.defaultValue}}</td>
                <td [innerHTML]="input.description ? input.description : '-' | safe: 'html'"></td>
              </tr>
            </ng-template>
          </ng-container>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- BRAND Variant -->
<div *ngIf="data?.cardItemTabs">
  <div *ngIf="data.mainTitle === 'iconography' && data.mainType === 'config' " class="p-grid u-margin-bottom--lg">
    <div class="p-col-4 p-lg-2">
      <p-button
        styleClass="p-button-raised p-button-success"
        label="Toggle color mode"
        (click)="toggleTheme()">
      </p-button>
    </div>
  </div>
  <div *ngFor="let tab of data.cardItemTabs" class="p-grid u-margin-bottom--xl">
    <h3 class="p-col-12 u-font-family--secondary u-margin-bottom--md">
      {{tab?.label}}
    </h3>
    <div class="p-col-4" *ngFor="let item of tab?.items">
      <mc-box [title]="item.title">
        <div
          [ngStyle]="{'height': tab?.type === 'colors' ? '100px' : 'auto', 'width': '100%' }"
          [ngClass]="[
            tab?.type === 'colors' ? item.utils[0] : '',
            tab?.type === 'measures' ? item.utils[0] : '',
            tab?.type === 'iconography' ? item.utils[0] : '',
            tab?.label === 'icon-size' ? 'u-icon--ball' : ''
          ]">
          <div
            *ngIf="tab?.type === 'measures'"
            class="u-background-color--info"
            [ngStyle]="{'height': item.value, 'width': '100%' }">
          </div>
        </div>
        <br>
        <div>
          <div [ngClass]="[
              tab?.label === 'font-family' ? item.utils[0] : '',
              tab?.label === 'font-size' ? item.utils[1] : ''
            ]">
          </div>
          <div *ngIf="item.value !== undefined" class="u-margin-bottom--xl u-font-size--sm">
            <code style="line-height: 1.5;">
              {{item.value}}<br>{{item.code}}
            </code>
          </div>
        </div>
        <div *ngIf="
            tab?.label === 'space' ||
            tab?.label === 'icon' ||
            tab?.label === 'icon-size' ||
            tab?.label === 'font-family' ||
            tab?.label === 'font-size' ||
            tab?.type === 'colors'
          ">
          <h4>Class Utilities</h4>
          <code  *ngFor="let util of item.utils" class="u-margin-bottom--xl u-font-size--sm" style="line-height: 1.5;"><br>
            {{util}}
          </code>
        </div>
      </mc-box>
    </div>
  </div>
</div>
