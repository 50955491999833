import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class ChipPageExempleComponent implements OnInit {
        chipData: ChipData
        chipDataActive: ChipData
        chipDataDeactivated: ChipData

        constructor() { }

        ngOnInit() {
          this.chipData = { label: 'Default' }
          this.chipDataActive = { label: 'Active', state: ChipStates.Active }
          this.chipDataDeactivated = { label: 'Deactivated', state: ChipStates.Deactivated }
        }
      }
    `,
  },
  {
    label: '',
    description: '',
    content: `
      <mc-chip [data]="chipDataActive"></mc-chip>
    `,
  },
]
