import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MATCH_STATE_CONFIG, MatchStateState, State } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './match-state-page.constants'

@Component({
  selector: 'app-match-state-page',
  templateUrl: './match-state-page.component.html',
  styleUrls: ['./match-state-page.component.scss'],
})
export class MatchStatePageComponent implements OnInit {

  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  matchStateData: State[]

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {

    this.matchStateData = Object.entries(MATCH_STATE_CONFIG).map(([key, val]) => ({
      minute: val.state === MatchStateState.Live ? '45' : '',
      name: key,
    }))

    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      visualsUrl: 'https://zpl.io/25pddmr',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.matchStateData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
