import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class FieldDiagramExempleComponent implements OnInit {

        constructor() { }

        ngOnInit() {
          this.playerPositioningStandardHome = [
            {
              "title": "1 de 1 partidos (100%)",
              "positioningType": "1-4-4-2",
              "players": [
                {
                  "number": 1,
                  "starter": 1,
                  "coordinates": [
                    5,
                    50
                  ],
                  "opacity": 1,
                  "size": 1,
                  "category": 0,
                },
                {
                  "number": 3,
                  "starter": 1,
                  "coordinates": [
                    25,
                    38
                  ],
                  "matrix": null,
                  "opacity": 3,
                  "size": 1,
                  "category": 0,
                  "extras": null
                }
              ],
              "connections": [
                {
                  "opacity": 3,
                  "origin": {
                    "coordinates": [
                      50,
                      63
                    ],
                    "size": 2
                  },
                  "target": {
                    "coordinates": [
                      25,
                      88
                    ],
                    "size": 3
                  }
                },
                {
                  "opacity": 3,
                  "origin": {
                    "coordinates": [
                      50,
                      13
                    ],
                    "size": 2
                  },
                  "target": {
                    "coordinates": [
                      75,
                      63
                    ],
                    "size": 1
                  }
                }
              ]
            }
          ]
        }
      }
    `,
  },
  {
    content: `
      <mc-field-diagram
        *ngFor="let item of playerPositioningStandardHome"
        [fieldDiagramData]="item">
      </mc-field-diagram>
    `,
  },
  {
    description: `
    You can also configure the direction of the field arrow (left, right ), the type of the team (home and away),
    if you want show player popup, reset field selection when click outside, use a custom template for player popup,
      user gradient and full opacity modifiers, set a connection coordinate type (origin, target),
      the field mode (color)

    `,
    content: `
      <mc-field-diagram
        *ngFor="let item of playerPositioningStandardHome"
        [type]="Team.Away"
        [showPopUp]="true"
        [resetWhenClickOutside]="true"
        [playerPopUpTemplate]="template"
        [arrowDirection]="FieldDiagramArrowDirection.Left"
        [connectionCoordinateType]="ConnectionCoordinateType.Origin"
        [modifiers]="[FieldDiagramModifier.Gradient, FieldDiagramModifier.FullOpacity]"
        [fieldDiagramMode]="FieldDiagramMode.Color"
        [fieldDiagramData]="item">
      </mc-field-diagram>
    `,
  },
]
