<header class="doc-header">
  <div class="brand-logo">
    <img src="{{logoPath}}" alt="MediaCoach Logo" routerLink="/">
  </div>
  <mc-search></mc-search>
  <div class="languages">
    <span [class.active-lang]='lang === currentLang' *ngFor="let lang of langs" (click)="changeLang(lang)">{{lang}}</span>
  </div>
  <span class="subtitle">{{headerTitle}}</span>
</header>
