import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { MainRoutes } from './routing.constants'

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(MainRoutes)],
})
export class AppRoutingModule { }
