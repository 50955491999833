import { Component, OnInit } from '@angular/core'
import { ErrorType, LayoutType } from '@mediacoach-ui-library/global'

@Component({
  selector: 'app-feedback-display-page',
  templateUrl: './error-feedback-page.component.html',
  styleUrls: ['./error-feedback-page.component.scss'],
})
export class ErrorFeedbackPageComponent implements OnInit {
  private _selectedErrorType = ErrorType.Default
  private _selectedLayoutType = LayoutType.Default

  errorTypes = Object.values(ErrorType)
  layoutTypes = Object.values(LayoutType)

  set selectedErrorType(_selectedErrorType: ErrorType) {
    this._selectedErrorType = _selectedErrorType
  }
  get selectedErrorType(): ErrorType {
    return this._selectedErrorType
  }

  set selectedLayoutType(_selectedLayoutType: LayoutType) {
    this._selectedLayoutType = _selectedLayoutType
  }
  get selectedLayoutType(): LayoutType {
    return this._selectedLayoutType
  }

  constructor() { }

  ngOnInit() {
  }
}
