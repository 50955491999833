
export const DEV_TABS = [
  {
    content: `
      <mc-team-select
        [teams]="teamSelectMockData"
        [selectedTeam]="demoSelectedTeam.id"
        (onTeamClicked)="setSelectedTeam($event)">
      </mc-team-select>
    `,
  },
]
