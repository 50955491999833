<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Default</h5>
        <mc-media-item [data]="mediaItemData"></mc-media-item>
      </div>
      <div class="p-col-12 p-md-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Inline / Reverse</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[mediaItemModifier.Inline, mediaItemModifier.Reverse]">
        </mc-media-item>
      </div>
      <div class="p-col-12 p-md-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Inline</h5>
        <mc-media-item [data]="mediaItemData" [modifiers]="[mediaItemModifier.Inline]"></mc-media-item>
      </div>
      <div class="p-col-12 p-md-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Card</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.Card
          ]">
        </mc-media-item>
      </div>
      <div class="p-col-12 p-md-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Card / Reverse</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.Card,
            mediaItemModifier.Reverse
          ]">
        </mc-media-item>
      </div>
    </div>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-6 u-background-color--grey--90">
        <h5 class="u-margin-bottom--lg u-color--white">Inline / Dark / Banner / SizeLg</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.Banner,
            mediaItemModifier.SizeLg,
            mediaItemModifier.Dark,
            mediaItemModifier.Inline
          ]">
        </mc-media-item>
      </div>
      <div class="p-col-12 p-md-6 u-background-color--grey--90">
        <h5 class="u-margin-bottom--lg u-color--white">Inline / Dark / Banner / Reverse / SizeLg</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.Inline,
            mediaItemModifier.Dark,
            mediaItemModifier.SizeLg,
            mediaItemModifier.Banner,
            mediaItemModifier.Reverse
          ]">
        </mc-media-item>
      </div>
    </div>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Inline / Banner / SizeXl</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.Banner,
            mediaItemModifier.SizeXl,
            mediaItemModifier.Inline
          ]">
        </mc-media-item>
      </div>
      <div class="p-col-12 p-md-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Inline / SizeSm</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.SizeSm,
            mediaItemModifier.Inline
          ]">
        </mc-media-item>
      </div>
      <div class="p-col-12 p-md-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">SizeMd / Inline</h5>
        <mc-media-item
          [data]="mediaItemData"
          [modifiers]="[
            mediaItemModifier.Inline,
            mediaItemModifier.SizeMd
          ]">
        </mc-media-item>
      </div>
      <div class="p-col-12 p-md-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Without Text</h5>
        <mc-media-item
          [data]="{
            media: 'https://statics-test01.mediacoach.es/mcpfilestest01/statics/teams/20200210/39f3424c-8910-744a-1da4-fd107a4580de?st=yCpjp9hdpjBi80sj7dvzhA&e=1591787002&n=2019_Valencia_CF_Small2x_Portrait.png',
            text: ''
          }">
        </mc-media-item>
      </div>
    </div>
  </ng-template>
</app-overview-template>
