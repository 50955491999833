<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-margin-bottom--xl">
      <div class="p-col-12 u-margin-bottom--xl">
      </div>
      <div class="p-col-12 p-md-6">
        <div style="height: 47px"></div>
        <mc-field-diagram
          *ngFor="let item of playerPositioningStandardHome"
          [fieldDiagramData]="item"
          [modifiers]="[fieldDiagramModifier.Gradient, fieldDiagramModifier.FullOpacity]">
        </mc-field-diagram>
      </div>
      <div class="p-col-12 p-md-6">
        <div class="c-field-diagram__buttons-container">
          <mc-button
            *ngFor="let connection of [connectionCoordinateType.Target, connectionCoordinateType.Origin]"
            [text]="connection"
            (click)="changeFieldDiagramA(connection)">
          </mc-button>
        </div>
        <mc-field-diagram
          type="away"
          *ngFor="let item of playerPositioningStandardAway"
          [fieldDiagramData]="item"
          [connectionCoordinateType]="selectedConnectionTypeA"
          [arrowDirection]="fieldDiagramArrowDirection.Left"
          [selectedPlayerNumber]="3"
          [modifiers]="[fieldDiagramModifier.Gradient, fieldDiagramModifier.FullOpacity]">
        </mc-field-diagram>
      </div>
    </div>
    <div class="p-grid u-margin-bottom--xl">
      <div class="p-col-12 u-margin-bottom--md">
        <h4 class="u-font-family--secondary">Campograma de Posicionamiento del Equipo v3.0</h4>
      </div>
      <div class="p-col-12 p-md-6">
        <mc-field-diagram
          *ngFor="let item of playerPositioningFinalHome"
          [fieldDiagramData]="item"
          [isColored]="true">
        </mc-field-diagram>
      </div>
      <div class="p-col-12 p-md-6">
        <mc-field-diagram
          type="away"
          *ngFor="let item of playerPositioningFinalAway"
          [arrowDirection]="fieldDiagramArrowDirection.Left"
          [isColored]="true"
          [fieldDiagramData]="item">
        </mc-field-diagram>
      </div>
    </div>
  </ng-template>
</app-overview-template>
