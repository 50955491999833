<section class="container">
  <h2 class="u-margin-bottom--xl">{{pageTitle}}</h2>
  <div class="p-grid">
    <div class="p-col-12 p-lg-2">
      <div class="menu-desktop">
        <p-menu [model]="menuItems"></p-menu>
      </div>
      <div class="menu-mobile">
        <mc-overlay [type]="overlayType.SidePanel" title="Components" #overlay>
          <div overlay-template="body">
            <p-menu [model]="menuItems"></p-menu>
          </div>
        </mc-overlay>
        <button type="text" (click)="overlay.open()" pButton icon="pi pi-plus" label="Components"></button>
      </div>
    </div>
    <div class="p-col-12 p-lg-10">
      <div class="u-margin-bottom--xl">
        <p-tabMenu [model]="tabMenuItems"></p-tabMenu>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
