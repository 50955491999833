import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-fallback-page',
  templateUrl: './fallback-page.component.html',
  styleUrls: ['./fallback-page.component.scss'],
})
export class FallbackPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
