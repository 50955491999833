import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { ActivatedRoute } from '@angular/router'
import { Colors, IconSize, IconType, RatingModel } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from '../field-diagram-page/field-diagram-page.constants'

@Component({
  selector: 'app-rating-page',
  templateUrl: './rating-page.component.html',
  styleUrls: ['./rating-page.component.scss'],
})
export class RatingPageComponent implements OnInit {
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>

  public rating: RatingModel = {
    matched: true,
    maxRating: 5,
    iconType: IconType.Football,
    iconSize: IconSize.Lg,
    defaultColor: Colors.Default,
    blocks: [
      {
        rate: 3,
        color: Colors.Away,
      },
      {
        rate: 4,
        color: Colors.Home,
      },
    ],
  }

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://app.zeplin.io/project/5d234ce6136a9099a395d713/screen/5d2c56c85e43774c46917449',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.rating)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
