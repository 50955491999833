import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      <mc-timeline [data]="data" [separatePeriods]="false"></mc-timeline>
    `,
  },
]

