export enum AppSection {
  Framework = 'framework',
  Components = 'components',
  ChartComponents = 'chart-components',
  Services = 'services',
  Directives = 'directives',
  Pipes = 'pipes',
  Utils = 'utils',
  Core = 'core',
}
