import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { untilDestroyed } from 'ngx-take-until-destroy'

import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './title-page.constants'
import * as titleData from './title.mock.json'

@Component({
  selector: 'app-title',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.scss'],
})
export class TitlePageComponent implements OnInit, OnDestroy {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  titleMockData = titleData['default']

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) {
  }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCPF/List',
      visualsUrl: 'https://app.zeplin.io/project/58e60ec50c7ed3178713d34a/screen/5f68ca0bab272704c6b165a2',
    })

    this._route.data.pipe(
      untilDestroyed(this),
    ).subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.titleMockData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  ngOnDestroy() {
  }
}
