<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-4">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Default</h5>
        <mc-match-item [match]="matchInfoData" [hasMediaItemContainer]="true" ></mc-match-item>
      </div>
      <div class="p-col-12 p-md-4">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Type Small / hasState</h5>
         <mc-match-item
          [match]="matchInfoData"
          [hasState]="true"
          [type]="matchItemType.Small">
        </mc-match-item>
      </div>
      <div class="p-col-12 p-md-4">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Type Reduced</h5>
        <mc-match-item
          [match]="matchInfoData"
          [type]="matchItemType.Reduced">
        </mc-match-item>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Modifier Card / hasState</h5>
        <mc-box
          [hasDecoration]="true"
          [modifers]="[boxModifier.Bottom, boxModifier.Horizontal, boxModifier.Gradient]">
          <div class="u-padding-v--xl">
            <mc-match-item
              [match]="matchInfoData"
              [hasState]="true"
              [modifiers]="[matchItemModifier.Card]">
            </mc-match-item>
          </div>
        </mc-box>
      </div>
      <div class="p-col-12 p-md-6">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Default / hasState</h5>
        <mc-match-item
          [match]="matchInfoData"
          [hasState]="true">
        </mc-match-item>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Modifier Banner / Modifier SizeLg</h5>
        <mc-match-item
          [match]="matchInfoData"
          [modifiers]="[matchItemModifier.Banner, matchItemModifier.SizeLg]">
        </mc-match-item>
      </div>
      <div class="p-col-12 u-background-color--body">
        <h5 class="u-margin-bottom--lg u-color--white">Modifier Banner / Modifier SizeLg / Modifier Dark</h5>
        <mc-match-item
          [match]="matchInfoData"
          [modifiers]="[matchItemModifier.Banner, matchItemModifier.Dark, matchItemModifier.SizeLg]"
          [type]="">
        </mc-match-item>
      </div>
    </div>
  </ng-template>
</app-overview-template>
