import { LABEL_CONFIGURATION, LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: 'Use the parseMatrix() utility, to transform the string into an array.',
    content: `
      import { parseMatrix } from '@mediacoach-ui-library/global'

      export class HeatMapExampleComponent implements OnInit {

        heatMapRawData = "000000000000000000000000000000000000000000000000000
        000000000000000010000000000000000000000001141000000000000000001001122
        354110000001401000311122233498431111003100000111223744677541001112000
        000101112475544300000000000001000011125231000000000000000000111120100"

        heatMapData: number[]

        constructor() {}

        ngOnInit() {
          this.heatMapData = parseMatrix(heatMapRawData)
        }
      }
    `,
  },
  {
    content: `
      <mc-heat-map [heatMapData]="heatMapData"></mc-heat-map>
    `,
  },
  {
    label: LABEL_CONFIGURATION,
    description: 'Configure other colors and the intensity of blur for the component.',
    content: `
      export class HeatMapExampleComponent {
        heatMapConfig = {
          colors: [ '#99DDFF','#80FFBF', '#70FA9E' ],
          blur: 4
        }
        constructor() {}
      }
    `,
  },
  {
    content: `
      <mc-heat-map [heatMapConfig]="heatMapConfig" [heatMapData]="heatMapData"></mc-heat-map>
    `,
  },
]
