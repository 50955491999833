<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div *ngFor="let item of matchStateData" class="p-col-2">
        <mc-match-state [matchState]="item"></mc-match-state>
      </div>
    </div>

  </ng-template>
</app-overview-template>
