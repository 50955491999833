<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="c-heat-map__page-wrapper">
      <mc-button
        *ngFor="let matrixOption of heatMapMatrixOptions; let i = index"
        [text]="'Matrix: ' + matrixOption + (i === 0 ? ' default' : '')"
        (click)="changeHeatMap(matrixOption)">
      </mc-button>
    </div>
    <p *ngIf="textVisible">Heatmap de <strong>Messi</strong> en el partido <strong>FC Barcelona - Real Valladolid</strong> del 29/10/19</p>
    <div class="p-grid">
      <div class="p-col-6">
        <mc-heat-map
          [heatMapData]="heatMapData">
        </mc-heat-map>
      </div>
      <div class="p-col-6">
        <mc-heat-map
          [heatMapData]="heatMapData"
          [isColored]="true"
          [heatMapConfig]="{ blur: 4 }">
        </mc-heat-map>
      </div>
    </div>
  </ng-template>
</app-overview-template>
