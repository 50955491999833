import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { ActivatedRoute } from '@angular/router'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './team-trend-table-page.constants'
import * as teamTrend from './team-trend-table.json'

@Component({
  selector: 'mc-team-trend-table-page',
  templateUrl: './team-trend-table-page.component.html',
  styleUrls: ['./team-trend-table-page.component.scss'],
})
export class TeamTrendTablePageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  teamTrendData = teamTrend['default'].data

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCIU/Tabla+de+Tendencia+Equipo',
      visualsUrl: 'https://zpl.io/VxRdkrm',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.teamTrendData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
