<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid">
      <div class="p-col-12">
        <p>El componente "Overlay" puede ser utilizado como "modal" o como "side-drawer". A continuación pueden
        verse dos ejemplos haciendo "click" en los distintos botones:</p>
      </div>
      <div class="p-col-6">
        <mc-button text="Mostrar Overlay (Modal)" (click)="modal.open()"></mc-button>
      </div>
      <div class="p-col-6">
        <mc-button text="Mostrar Overlay (Side Drawer)" (click)="sideDrawer.open()"></mc-button>
      </div>
    </div>

    <mc-overlay
      [type]="OverlayType.Modal"
      [title]="'Esto es un Overlay de tipo Modal'"
      [subtitle]="'Título y subtítulo pueden ser personalizados'" #modal>
        <div class="c-profile" overlay-template="body">
          <p>El cuerpo del overlay puede ser también personalizado, añadiendo contenidos a la template "body".</p>
        </div>
        <div overlay-template="footer">
          <div class="c-button-group c-button-group--separated">
            <mc-button text="Close" (click)="modal.close()"></mc-button>
          </div>
        </div>
    </mc-overlay>

    <mc-overlay
      [type]="OverlayType.SidePanel"
      [title]="'Esto es un Overlay de tipo Overlay'"
      [subtitle]="'Título y subtítulo pueden ser personalizados'" #sideDrawer>
      <div class="c-profile" overlay-template="body">
        <p>Este es otro tipo de Overlay</p>
        <p>El cuerpo del overlay puede ser también personalizado, añadiendo contenidos a la template "body".</p>
      </div>
      <div overlay-template="footer">
        <div class="c-button-group c-button-group--separated">
          <mc-button text="Close" (click)="sideDrawer.close()"></mc-button>
        </div>
      </div>
    </mc-overlay>

    <div class="p-grid">
      <div class="p-col-12">
        <p>Por otro lado, el componente "overlay" dispone de un "Loader" personalizable en caso que los contenidos
        que se muestran tarden en cargarse. El icono puede ser cambiado utilizando el atributo "spinnerIcon" y
        especificando una clase para el icono deseado. Por defecto, si no se especifica ningún icono, mostrará un balón botando.</p>
      </div>
      <div class="p-col-6">
        <mc-button text='Mostrar Overlay "Loading" (Modal)' (click)="modalLoading.open()"></mc-button>
      </div>
    </div>

    <mc-overlay
      [type]="OverlayType.Modal"
      [title]="'Overlay tipo Modal Cargando contenido'"
      [subtitle]="'El loading ha sido forzado para esta presentación'"
      [isLoading]="true" #modalLoading>
      <div class="c-profile" overlay-template="body">
      </div>
      <div overlay-template="footer">
        <div class="c-button-group c-button-group--separated">
          <mc-button text="Close" (click)="modalLoading.close()"></mc-button>
        </div>
      </div>
    </mc-overlay>
  </ng-template>
</app-overview-template>
