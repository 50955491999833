import { LABEL_CONFIGURATION, LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class MultiLineDiagramExempleComponent implements OnInit {

        sharedMultiLineDiagramDimensions = { width: 500, height: 300 }

        sharedMultiLineDiagramParams = {
          margin: { top: 5, right: 13, bottom: 8, left: 30 },
          hideHelperLines: { x: true }
        }

        sharedMultiLineDiagramData: [
          {
            "name": "home",
            "order": 0,
            "format": "%",
            "round": 0,
            "values": [
              {
                "labels": [
                  {
                    "order": 0,
                    "key": null,
                    "dynamic": null,
                    "value": "15'"
                  }
                ],
                "order": 0,
                "value": 60.4982206405694
              }
            ]
          },
          {
            "name": "away",
            "order": 1,
            "format": "%",
            "round": 0,
            "values": [
              {
                "labels": [
                  {
                    "order": 0,
                    "key": null,
                    "dynamic": null,
                    "value": "15'"
                  }
                ],
                "order": 0,
                "value": 39.5017793594306
              }
            ]
          }
        ]
        constructor() { }
      }
    `,
  },
  {
    content: `
      <mc-multiline-diagram
        [dimensions]="sharedMultiLineDiagramDimensions"
        [data]="sharedMultiLineDiagramData"
        [params]="sharedMultiLineDiagramParams">
      </mc-multiline-diagram>
    `,
  },
]
