import { Component } from '@angular/core'
import { SideNavModel } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { AngularFireAuth } from '@angular/fire/compat/auth'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Mediacoach UI Components'
  logo = 'assets/logos/logo-mediacoach.svg'
  NAV_ICONS = {
    [AppSection.Core]: 'pi pi-cog',
    [AppSection.Framework]: 'pi pi-palette',
    [AppSection.Components]: 'pi pi-th-large',
    [AppSection.ChartComponents]: 'pi pi-chart-bar',
    [AppSection.Directives]: 'pi pi-tag',
    [AppSection.Services]: 'pi pi-globe',
    [AppSection.Pipes]: 'pi pi-filter',
    [AppSection.Utils]: 'pi pi-compass',
  }
  navigationData: SideNavModel[] = Object.entries(AppSection).map(([key, val]) => ({
    name: key,
    icon: this.NAV_ICONS[val],
    url: val,
  }))

  constructor(public auth: AngularFireAuth) {}

  logout() {
    this.auth.signOut();
  }

}
