import { Injectable } from '@angular/core'
import { OverviewData, OverviewDataDocTabs } from '@shared/components/overview-template/overview-data.model'
import { MODULE_DOC } from '../constants/documentation-data.constants'
import { AppSection } from '../models/app.constants'
import { DocumentationDataComponent, DocumentationDataType } from '../models/documentation-data.models'

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {

  constructor() {}

  private getCardItems(section: string, type: string) {
    const hasUtils = MODULE_DOC[AppSection.Framework]
      .filter((item) => item.name === section)[0]['items'].map((item) => item.name).includes('utils')
    return this.getDetail(section, type).map((tab) => ({
      type: section,
      label: tab.name,
      items: tab.content.map((item) => ({
        name: item.type,
        title: `${tab.name} ${item.type}`,
        value: item.value,
        code: item.name,
        utils: hasUtils ? this.getDetail(section, 'utils').map((util) => `${util.namespace}-${util.name}--${item.type}`) : [],
      })),
    }))
  }

  private getSectionItems(section: string, type: string) {
    return MODULE_DOC[AppSection.Framework].find((item) => item.name === section).items.find((item) => item.name === type).items
  }

  getDetail(section: string, type: string) {
    return MODULE_DOC[AppSection.Framework]
      .filter((item) => item['name'] === section)[0]['items']
      .filter((item) => item['name'] === type)[0]['items']
  }

  getUtils(section: string, type: string) {
    const concatArr = (a, b) => a.concat(b)

    const utilsMerged = this.getSectionItems(section, 'utils')
      .map((util) => this.getSectionItems(section, 'config')
      .filter((item) => item.type === util.type)
      .map((sectionItem) => sectionItem.content.map((item) => ({
        name: util.name,
        value: `${util.namespace}-${util.name}--${item.type}`,
      })))
      .reduce(concatArr, []))
      .reduce(concatArr, [])

    return utilsMerged.reduce((acc, item) => ({
      ...acc,
      [item.name]: {
        name: item.name,
        items: utilsMerged
          .filter((i) => i.name === item.name)
          .reduce((a, b) => a.concat(b.value), []),
      },
    }), {})[type]
  }

  getFrameworkData(section: AppSection, type: string): OverviewData {
    return {
      templateType: type === 'config' ? 'card-item-tabs' : 'void-tabs',
      hasDevContent: type !== 'config',
      mainTitle: section,
      mainType: type,
      mainDescription: section,
      cardItemTabs: type === 'config' ? this.getCardItems(section, type) : [],
    }
  }

  getComponentData(data, mockData?): OverviewData {
    const component: DocumentationDataComponent = data.element
    return {
      templateType: 'void-tabs',
      hasDevContent: true,
      mainTitle: component.name,
      mainType: component.type,
      mainDescription: component.description,
      coverage: (MODULE_DOC[data.type].coverage.files || []).find((componentItem) => componentItem.name === component.name),
      file: component.file,
      template: component.templateData,
      code: component.sourceCode,
      mockData: mockData ? JSON.stringify(mockData, null, 2) : '',
    }
  }

  getComponentDocData(data): OverviewDataDocTabs[] {
    const component: DocumentationDataComponent = data.element
    const componentName = (component.selector || '').replace(/(mc-|\[|\])/g, '')
    const reFullFile = new RegExp(component.file, 'g')
    const reComponent = new RegExp(`/${componentName}.component.ts`, 'g')
    const reComponentPath = new RegExp(component.file.replace(reComponent, ''))
    const matchFiles = (item) => reFullFile.test(item.file) || reComponentPath.test(item.file)

    return [
      {
        type: DocumentationDataType.Inputs,
        content: component.inputsClass || [],
      },
      {
        type: DocumentationDataType.Output,
        content: component.outputsClass || [],
      },
      {
        type: DocumentationDataType.HostBindings,
        content: component.hostBindings || [],
      },
      {
        type: DocumentationDataType.Properties,
        content: component.propertiesClass || [],
      },
      {
        type: DocumentationDataType.Methods,
        content: component.methodsClass || [],
      },
      {
        type: DocumentationDataType.Methods,
        content: component.methods || [],
      },
      {
        type: DocumentationDataType.Interfaces,
        content: (MODULE_DOC[data.type].interfaces || []).filter(matchFiles) || [],
      },
      {
        type: DocumentationDataType.Enumerations,
        content: (MODULE_DOC[data.type].miscellaneous.enumerations || []).filter(matchFiles) || [],
      },
      {
        type: DocumentationDataType.Constants,
        content: (MODULE_DOC[data.type].miscellaneous.variables || []).filter(matchFiles) || [],
      },
    ]
  }
}
