const utilsTemplate = (data) => `
  <div class="u-margin-bottom--xl">
    <div class="p-grid">
      ${((data || {}).items || []).map((util) => `
        <div class="u-margin-bottom--md p-col-3">
          ${data.name === 'background-color' ? `
            <div class="u-margin-bottom--md">${util}</div>
            <div class="u-margin-bottom--md u-padding-v--md ${util}"></div>
          ` : ``}
          ${data.name === 'color' ? `
            <span class="u-icon--arrow-right ${util}"></span>
            <span>${util}</span>
          ` : ``}
          ${data.name === 'icon' ? `
            <div class=" ${data.name === 'icon-size' ? `u-icon--ball` : ``} ${util}"></div>
            <span>${util}</span>
          ` : ``}
        </div>
      `).join('')}
    </div>
  </div>
`

export const PAGES_CONTENT = {
  'flex-grid': `
    <a href="https://www.primefaces.org/primeng/#/flexgrid" target="_blank">
      <h3>Official Documentation</h3>
    </a>
    <br>
    <p>Exemple:</p>
    <div class="p-grid u-background-color--info u-color--white" style="text-align: center">
      <div class="p-col" style="border: solid 2px #ffffff">1</div>
      <div class="p-col" style="border: solid 2px #ffffff">2</div>
      <div class="p-col" style="border: solid 2px #ffffff">3</div>
    </div>
  `,
  'background-color': (data) => utilsTemplate(data),
  'color': (data) => utilsTemplate(data),
  'icon': (data) => utilsTemplate(data),
  'margin-bottom-children': (data) => utilsTemplate(data),
  'margin-bottom': (data) => utilsTemplate(data),
  'padding-h': (data) => utilsTemplate(data),
  'padding-v': (data) => utilsTemplate(data),
  'padding': (data) => utilsTemplate(data),
  'font-family': (data) => utilsTemplate(data),
  'font-size': (data) => utilsTemplate(data),
  'line-height': (data) => utilsTemplate(data),
}

export const PAGES_DEV_CONTENT = {
  'flex-grid': `
    <div class="p-grid">
      <div class="p-col">1</div>
      <div class="p-col">2</div>
      <div class="p-col">3</div>
    </div>
  `,
  'background-color': `
    // HTML Example:

    <div class="u-background-color--info"></div>

    // Mixim Example:

    @include t-utils-property($map-color-brand, 'background-color');
  `,
  'color': `
    // HTML Example:

    <div class="u-color--info"></div>

    // Mixim Example:

    @include t-utils-property($map-color-brand, 'color');
  `,
  'icon': `
    // HTML Example:

    <i class="u-icon u-icon--ball"></i>

    // Mixim Example:

    @include p-icon__styles;
    @include p-icon(ball);
  `,
}
