import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ComponentsModule, CoreModule, TranslatorService } from '@mediacoach-ui-library/global'
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs'
import { ButtonModule } from 'primeng/button'
import { TableModule } from 'primeng/table'
import { HeaderComponent } from './components/header/header.component'
import { OverviewTemplateComponent } from './components/overview-template/overview-template.component'
import { InputTextModule } from 'primeng/inputtext'

@NgModule({
  declarations: [
    HeaderComponent,
    OverviewTemplateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    CoreModule,
    HighlightModule,
    TableModule,
    ButtonModule,
    InputTextModule,
  ],
  exports: [
    HeaderComponent,
    OverviewTemplateComponent,
    ComponentsModule,
    CoreModule,
    ButtonModule,
    InputTextModule,
  ],
  providers: [
    TranslatorService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml')
        },
      }
    }
  ],
})
export class SharedModule { }
