import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class CollapsePageExempleComponent implements OnInit {
        isCollapse = false

        constructor() { }
      }
    `,
  },
  {
    label: '',
    description: '',
    content: `
      <mc-collapse [(isCollapsed)]="isCollapse">
       <h1>Collapse content</h1>
     </mc-collapse>
    `,
  },
]
