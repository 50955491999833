import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { SharedDataService } from '@core/services/shared-data.service'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { PAGES_CONTENT, PAGES_DEV_CONTENT } from './shared-page.constants'

@Component({
  selector: 'app-shared-page',
  templateUrl: './shared-page.component.html',
  styleUrls: ['./shared-page.component.scss'],
})
export class SharedPageComponent implements OnInit, OnDestroy {

  private _overviewData$$ = new BehaviorSubject<any>(null)
  private _overviewDevData$$ = new BehaviorSubject<any>(null)

  @ViewChild('htmlCode', { static: true }) htmlCode: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  routerEventsSubscription: Subscription

  overviewData$: Observable<OverviewData> = this._overviewData$$.pipe(filter((data) => !!data))
  overviewDevData$: Observable<OverviewDevData> = this._overviewDevData$$.pipe(filter((data) => !!data))

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: SharedDataService,
  ) { }

  private _refreshData(params) {
    this._overviewData$$.next(this._dataService.getFrameworkData(params.type, params.id))

    if (params.id === 'utils' || params.id === 'patterns') {
      this._overviewDevData$$.next(
        {
          voidTabs: this._dataService.getDetail(params.type, params.id).map((tab) => ({
            label: tab.name,
            template: this.htmlCode,
            content: params.id === 'utils' ? PAGES_CONTENT[tab.name](this._dataService.getUtils(params.type, tab.name)) : PAGES_CONTENT[tab.name],
            test: this._dataService.getDetail(params.type, params.id),
            devTabs: [
              {
                label: 'CODE',
                content: PAGES_DEV_CONTENT[tab.name],
              },
            ],
          })),
        },
      )
    } else  {
      this._overviewDevData$$.next({})
    }
  }

  ngOnInit() {
    this.routerEventsSubscription = this._router.events.pipe(
      filter((routeInfo) => routeInfo instanceof NavigationEnd),
    ).subscribe(() => {
      this._refreshData({ ...this._route.snapshot.params, ...this._route.parent.snapshot.params })
    })
    this._refreshData({ ...this._route.snapshot.params, ...this._route.parent.snapshot.params })
  }

  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe()
  }
}
