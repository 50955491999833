import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { ActivatedRoute } from '@angular/router'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import * as mockData from './mockData.json'
import { DEV_TABS } from './pass-matrix-page.constants'

@Component({
  selector: 'app-pass-matrix-page',
  templateUrl: './pass-matrix-page.component.html',
  styleUrls: ['./pass-matrix-page.component.scss'],
})
export class PassMatrixPageComponent implements OnInit {
  homeFlows = [...mockData['default'].data.home.flows]
  awayFlows = [...mockData['default'].data.away.flows]
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  @ViewChild('componentPlaceholder', { static: true }) componentPlaceholder: TemplateRef<any>

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) {}

  ngOnInit() {

    this.overviewDevData = setOverviewDevData({
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/display/MCIU/Mapping+componentes+Informes+VS+web',
      visualsUrl: 'https://zpl.io/VOKGK01',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.homeFlows)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
