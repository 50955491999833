<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-xl-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Default</h5>
        <mc-application-menu
          [config]="appConfig"
          [applications]="userProfile"
          (onDeactivated)="overlay.open()"
          (onDeepLink)="gotoDeepLink($event)">
        </mc-application-menu>
      </div>
      <div class="p-col-12 p-xl-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Labeled</h5>
        <mc-application-menu
          [config]="appConfig"
          [label]="'APPS'"
          [applications]="userProfile">
        </mc-application-menu>
      </div>
      <div class="p-col-12 p-xl-3">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Page Mode</h5>
        <mc-application-menu
          [config]="appConfig"
          [applications]="userProfile"
          (onDeactivated)="overlay.open()"
          (onDeepLink)="gotoDeepLink($event)"
          [pageMode]="true">
        </mc-application-menu>
      </div>
    </div>
    <mc-overlay
      [type]="OverlayType.Modal"
      [title]="modalTitle" #overlay>
        <div overlay-template="footer">
          <div class="c-button-group c-button-group--separated">
            <mc-button text="Close" (click)="overlay.close()"></mc-button>
          </div>
        </div>
    </mc-overlay>
  </ng-template>
</app-overview-template>
