import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './overlay-panel-page.constants'

@Component({
  selector: 'app-overlay-panel-page',
  templateUrl: './overlay-panel-page.component.html',
})
export class OverlayPanelPageComponent implements OnInit {
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) {}

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://app.zeplin.io/project/5d234ce6136a9099a395d713/screen/5d2c563da4fb056c234da800',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
