import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class MatchStateExempleComponent implements OnInit {
        matchStateData: State[]

        constructor() { }

        ngOnInit() {

          this.matchStateData = {
            minute: '45',
            name: 'HalfTime'
          }
        }
      }
    `,
  },
  {
    label: '',
    description: '',
    content: `
      <mc-match-state [matchState]="matchStateData"></mc-match-state>
    `,
  },
]
