<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-4" style="background-color: forestgreen">
        <h5 class="u-margin-bottom--lg">Full</h5>
        <mc-match-item-mobile
          [match]="matchInfoData"
          [type]="MatchItemMobileType.Full">
        </mc-match-item-mobile>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-4">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Listed</h5>
        <mc-match-item-mobile
          [match]="matchInfoData"
          [type]="MatchItemMobileType.Listed">
        </mc-match-item-mobile>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-4" style="background-color: forestgreen">
        <h5 class="u-margin-bottom--lg">Compressed</h5>
        <mc-match-item-mobile
          [match]="matchInfoData"
          [type]="MatchItemMobileType.Compressed">
        </mc-match-item-mobile>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-4">
        <h5 class="u-margin-bottom--lg u-color--grey--50">FullListed</h5>
        <mc-match-item-mobile
          [match]="matchInfoData"
          [type]="MatchItemMobileType.FullListed">
        </mc-match-item-mobile>
      </div>
    </div>


  </ng-template>
</app-overview-template>
