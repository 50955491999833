import * as frameworkDocumentation from '@mediacoach-ui-library/framework/documentation-framework.json'
import * as globalComponentsDocumentation from '@mediacoach-ui-library/framework/documentation.json'
import { AppSection } from '../models/app.constants'
import { DocumentationData } from '../models/documentation-data.models'

const setRegExp = (string) => new RegExp(string, 'g')

const frameworkDocumentationData = frameworkDocumentation['default']
const globalComponentsDocumentationData: DocumentationData = globalComponentsDocumentation['default']
const utils = globalComponentsDocumentationData.miscellaneous.variables.filter((item) => setRegExp('util').test(item.file))
const core = globalComponentsDocumentationData.miscellaneous.enumerations
const { miscellaneous, coverage } = globalComponentsDocumentationData

export const MODULE_DOC = {
  [AppSection.Framework]: frameworkDocumentationData,
  [AppSection.Components]: {
    miscellaneous, coverage,
    elements: globalComponentsDocumentationData.components.filter(({file}) => file.indexOf('/lib/components/') > 0),
    interfaces: globalComponentsDocumentationData.interfaces,
  },
  [AppSection.ChartComponents]: {
    miscellaneous, coverage,
    elements: globalComponentsDocumentationData.components.filter(({file}) => file.indexOf('/lib/chart-components/') > 0),
    interfaces: globalComponentsDocumentationData.interfaces,
  },
  [AppSection.Directives]: {
    miscellaneous, coverage,
    elements: globalComponentsDocumentationData.directives,
  },
  [AppSection.Services]: {
    miscellaneous, coverage,
    elements: globalComponentsDocumentationData.injectables,
  },
  [AppSection.Pipes]: {
    miscellaneous, coverage,
    elements: globalComponentsDocumentationData.pipes,
  },
  [AppSection.Utils]: {
    miscellaneous, coverage,
    elements: [{
      name: 'Objects',
      file: utils.filter((i) => setRegExp('object').test(i.file))[0].file,
    }, {
      name: 'Environment',
      file: utils.filter((i) => setRegExp('environment').test(i.file))[0].file,
    }],
  },
  [AppSection.Core]: {
    miscellaneous, coverage,
    elements: [
      { name: 'Environment', file: 'environment' },
      { name: 'Application', file: 'application' },
      { name: 'User', file: 'user' },
      { name: 'Competition', file: 'competition' },
      { name: 'Match', file: 'match' },
      { name: 'Assets', file: 'assets' },
      { name: 'Components', file: 'components' },
    ],
  },
}
