<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>

    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-6 c-bgc-white">
        <h5 class="u-margin-bottom--lg">Metrics</h5>
        <mc-list
          [itemTemplate]="metric"
          [data]="listMockData?.metrics"
          [isCollapsible]="true">
        </mc-list>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-6 c-bgc-white">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Players</h5>
        <mc-list
          [itemTemplate]="player"
          [searchable]="true"
          [isCollapsible]="true"
          [data]="players"
        ></mc-list>
      </div>
    </div>
    <hr>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-12 p-md-6 c-bgc-white">
        <h5 class="u-margin-bottom--lg">Common</h5>
        <mc-list
          [itemTemplate]="common"
          [data]="listMockData?.commons"
        ></mc-list>
      </div>
    </div>

  </ng-template>
  <ng-template #metric let-item>
    <mc-metric-list-item [data]="item"></mc-metric-list-item>
  </ng-template>
  <ng-template #player let-item>
    <mc-player-list-item extraClasses="mc-player-list-item__hover" [data]="item"></mc-player-list-item>
  </ng-template>
  <ng-template #common let-item>
    <mc-common-list-item [data]="item"></mc-common-list-item>
  </ng-template>
</app-overview-template>
