import { Component, Input, OnInit } from '@angular/core'
import { I18N_LANGS, TranslatorService } from '@mediacoach-ui-library/global'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() public headerTitle: string
  @Input() public logoPath: string
  langs = Object.keys(I18N_LANGS)
  currentLang = this.translatorService.getCurrentLang()

  constructor(private translatorService: TranslatorService) { }

  ngOnInit() {
    this.translatorService.use(this.langs[0], true)
  }

  changeLang(language) {
    this.translatorService.use(language, true)
    this.currentLang = this.translatorService.getCurrentLang()
  }
}
