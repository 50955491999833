<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid u-padding-v--xl">
      <div class="p-col-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Default</h5>
        <mc-chip [data]="chipData"></mc-chip>
      </div>
      <div class="p-col-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Active</h5>
        <mc-chip [data]="chipDataActive"></mc-chip>
      </div>
      <div class="p-col-2">
        <h5 class="u-margin-bottom--lg u-color--grey--50">Deactivated</h5>
        <mc-chip [data]="chipDataDeactivated"></mc-chip>
      </div>
    </div>
  </ng-template>
</app-overview-template>
