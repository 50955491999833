import { TemplateRef } from '@angular/core'
import { AppSection } from '@core/models/app.constants'
import { OverviewDataVoidTabs } from './overview-data.model'

const LABEL_IMPORT = 'Import'
export const LABEL_EXAMPLE = 'Example'
export const LABEL_USAGE = 'Getting Started'
export const LABEL_USAGE_PAGE_MODE = 'Page Mode'
export const LABEL_CONFIGURATION = 'Configuration'
export const LABEL_DOC_EXTERNAL = 'Funcional'
export const LABEL_VISUALS_EXTERNAL = 'Visuals'
export const ICON_EXTERNAL = 'external-link'

export const DOC_EXTERNAL = {
  label: LABEL_DOC_EXTERNAL,
  icon: ICON_EXTERNAL,
}

export const VISUALS_EXTERNAL = {
  label: LABEL_VISUALS_EXTERNAL,
  icon: ICON_EXTERNAL,
}

const USAGE_IMPORT = {
  [AppSection.ChartComponents]: {
    label: LABEL_IMPORT,
    content: `import { ChartComponentsModule } from '@mediacoach-ui-library/global'`,
  },
  [AppSection.Components]: {
    label: LABEL_IMPORT,
    content: `import { ComponentsModule } from '@mediacoach-ui-library/global'`,
  },
  [AppSection.Core]: {
    label: LABEL_IMPORT,
    content: `import { ComponentsModule } from '@mediacoach-ui-library/global'`,
  },
}

interface devDataOptions {
  type?: AppSection,
  template?: TemplateRef<any>,
  docUrl?: string
  visualsUrl?: string
  dependencies?: string[]
  devTabs?: OverviewDataVoidTabs[],
  hasTheme?: boolean
}

export const setOverviewDevData = (options: devDataOptions) => {
  const {
    type = AppSection.ChartComponents,
    template,
    docUrl = '',
    visualsUrl = '',
    dependencies,
    devTabs,
    hasTheme,
  } = options

  return {
    mainDependencies: dependencies,
    hasTheme,
    externalURLs: [
      {
        ...DOC_EXTERNAL,
        destinationURL: docUrl,
      },
      {
        ...VISUALS_EXTERNAL,
        destinationURL: visualsUrl,
      },
    ],
    voidTabs: [
      {
        label: LABEL_EXAMPLE,
        template,
      },
    ],
    devTabs: [
      USAGE_IMPORT[type],
      ...devTabs,
    ],
  }
}
