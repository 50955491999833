import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      <mc-overlay
        [type]="OverlayType.SidePanel"
        [title]="'Esto es un Overlay de tipo Overlay'"
        [subtitle]="'Título y subtítulo pueden ser personalizados'" #sideDrawer>
        <div class="c-profile" overlay-template="body">
          <p>Este es otro tipo de Overlay</p>
        </div>
        <div overlay-template="footer">
          <div class="c-button-group c-button-group--separated">
            <mc-button text="Close" (click)="sideDrawer.close()"></mc-button>
          </div>
        </div>
      </mc-overlay>
    `,
  },
]
