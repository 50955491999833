import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './multiline-diagram-page.constants'
import * as multilinediagram from './multiline-diagram.json'

@Component({
  selector: 'app-buttons-page',
  templateUrl: './multiline-diagram-page.component.html',
  styleUrls: ['./multiline-diagram-page.component.scss'],
})
export class MultiLineDiagramPageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  sharedMultiLineDiagramDimensions = { width: 500, height: 300 }
  sharedMultiLineDiagramParams = {
    margin: { top: 5, right: 13, bottom: 8, left: 30 },
    hideHelperLines: { x: true },
  }
  sharedMultiLineDiagramData: object[]

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.sharedMultiLineDiagramData = multilinediagram['default'].data.series

    this.overviewDevData = setOverviewDevData({
      type: AppSection.ChartComponents,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/pages/viewpage.action?pageId=119177707',
      visualsUrl: 'https://zpl.io/aByD9Dm',
      dependencies: ['d3'],
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.sharedMultiLineDiagramData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }
}
