import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      <mc-button text="Button Primary"></mc-button>
      <mc-button text="Button Primary Disabled" [isDisabled]='true'></mc-button>

      <mc-button text="Button Primary Small"
                 [buttonModifiers]='[ButtonModifier.Small]'></mc-button>

      <mc-button text="Button Primary Block"
                 [buttonModifiers]='[ButtonModifier.Block]'></mc-button>

      <mc-button text="Button Primary Block Disabled"
                 [isDisabled]='true'
                 [buttonModifiers]='[ButtonModifier.Block]'></mc-button>

      <mc-button text="Button Primary Block and Small"
                 [buttonModifiers]='[ButtonModifier.Block, ButtonModifier.Small]'></mc-button>

      <mc-button text="Button Primary with icon"
                 [icon]="'whistle'"></mc-button>

      <mc-button text="Button Secondary"
                 [buttonType]='ButtonType.Secondary'></mc-button>

      <mc-button text="Button Secondary Disabled"
                 [isDisabled]='true'
                 [buttonType]='ButtonType.Secondary'></mc-button>

      <mc-button text="Button Secondary Small"
                 [buttonType]='ButtonType.Secondary'
                 [buttonModifiers]='[ButtonModifier.Small]'></mc-button>

      <mc-button text="Button Secondary Block"
                 [buttonType]='ButtonType.Secondary'
                 [buttonModifiers]='[ButtonModifier.Block]'></mc-button>

      <mc-button text="Button Flat"
                 [buttonType]='ButtonType.Flat'></mc-button>

      <mc-button text="Button Flat Small"
                 [buttonType]='ButtonType.Flat'
                 [buttonModifiers]='[ButtonModifier.Small]'></mc-button>

      <mc-button text="Button Flat Disabled"
                 [buttonType]='ButtonType.Flat'
                 [isDisabled]='true'></mc-button>

      <mc-button [icon]="'whistle'"
                 [buttonType]='ButtonType.Icon'></mc-button>

      <mc-button [icon]="'whistle'"
                 [isDisabled]='true'
                 [buttonType]='ButtonType.Icon'
                 [buttonModifiers]='[ButtonModifier.Small]'></mc-button>
    `,
  },
]
