import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      export class PassMatrixPageComponent implements AfterContentInit {
        homeFlows = [...mockData['default'].data.home.flows]
        constructor() { }
      }
    `,
  },
  {
    content: `
      <mc-pass-matrix [flows]="homeFlows"></mc-pass-matrix>
    `,
  },
]
