<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #componentPlaceholder>
    <div class="p-grid">
      <div class="p-col-6">
        <p>
          El componente Pass Matrix muestra la relación de pases entre los jugadores de un mismo equipo.
        </p>
        <mc-pass-matrix [flows]="homeFlows"></mc-pass-matrix>
      </div>
      <div class="p-col-6">
        <p>
          El componente se estila por defecto para el equipo local "home". Para instanciar el componente como equipo
          visitante, podemos utilizar el atributo <strong>[isAway]="true"</strong>. Al hacerlo, se aplicará la
          capa de estilos correspondiente al equipo visitante "away".
        </p>
        <mc-pass-matrix [flows]="awayFlows" [isAway]="true"></mc-pass-matrix>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-6">
        <p>
          Por defecto, la tabla abarca el 100% de ancho de su contenedor. Si se quiere
          que tan solo abarque su espacio necesario, se puede utilizar la opción <strong>[isCondensed]="true"</strong>.
        </p>
        <mc-pass-matrix [flows]="homeFlows" [isCondensed]="true"></mc-pass-matrix>
      </div>
    </div>
  </ng-template>
</app-overview-template>
