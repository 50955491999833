import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FieldDirection, parseMatrix } from '@mediacoach-ui-library/global'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './heat-map-page.constants'
import { heatMapMockData } from './mockData'

@Component({
  selector: 'app-heat-map-page',
  templateUrl: './heat-map-page.component.html',
  styleUrls: ['./heat-map-page.component.scss'],
})
export class HeatMapPageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  heatMapData: number[]
  heatMapMatrixOptions = Object.keys(heatMapMockData.matrix)
  textVisible = false
  arrowDirection = FieldDirection

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) {}

  ngOnInit() {
    this.changeHeatMap()

    this.overviewDevData = setOverviewDevData({
      type: AppSection.ChartComponents,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/pages/viewpage.action?pageId=122815794',
      visualsUrl: 'https://zpl.io/bld9jnv',
      dependencies: ['d3', 'd3-contour'],
      hasTheme: true,
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, heatMapMockData)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  changeHeatMap(matrixSelected = this.heatMapMatrixOptions[0]) {
    this.heatMapData = parseMatrix(heatMapMockData.matrix[matrixSelected])
    this.textVisible = matrixSelected === 'messiDemo'
  }
}
