<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div class="p-grid">
      <div class="p-col-12">
        <p>OverlayPanel de PrimeNg mejorado</p>
      </div>
      <div class="p-col-6">
        <mc-button text="Mostrar Overlay (Modal)" (click)="modal.show($event)"></mc-button>
        <mc-button text="Ocultar Overlay (Modal)" (click)="modal.hide()"></mc-button>
      </div>
    </div>

    <mc-overlay-panel #modal>
      <div style="color: white; padding: 16px;">
        <p>Contenido del overlay panel</p>
      </div>
    </mc-overlay-panel>
  </ng-template>
</app-overview-template>
