<ng-container  *ngIf="auth.user | async as user; else login">
  <app-header [headerTitle]="title" [logoPath]="logo"></app-header>
  <mc-side-nav [navItems]="navigationData"></mc-side-nav>
  <div
    (click)="logout()"
    class="sign-out">
    <i class="sign-out__icon pi pi-power-off"></i>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>


</ng-container>
<ng-template #login>
  <app-login></app-login>
</ng-template>
