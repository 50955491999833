<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #componentPlaceholder>
    <div class="wrapper">
      <div class="p-grid">
        <div class="p-col-4">
          <p>
            Sin datos
          </p>
          <mc-spider-chart [data]="[]" [header]="spiderHeader"></mc-spider-chart>
        </div>
        <div class="p-col-4">
          <p>
            Sin valores
          </p>
          <mc-spider-chart [data]="spiderDataNoValues" [header]="spiderHeader"></mc-spider-chart>
        </div>
        <div class="p-col-4">
          <p>
            Ejemplo completo
          </p>
          <mc-spider-chart [data]="spiderData" [header]="spiderHeader" [render$]="render$" ></mc-spider-chart>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-4">
          <p>
            Cabecera personalizada
          </p>
          <mc-spider-chart [data]="spiderData" [render$]="render$" >
            <ng-template mcTemplate="header">
              <h3>Esto es una cabecera personalizada</h3>
            </ng-template>
          </mc-spider-chart>
        </div>
      </div>
    </div>
  </ng-template>
</app-overview-template>
