import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AppSection } from '@core/models/app.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './error-page.constants'

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.overviewDevData = setOverviewDevData({
      type: AppSection.Components,
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/pages/viewpage.action?pageId=160214536',
      visualsUrl: 'https://zpl.io/a7B0ZZY',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

}
