import { LABEL_USAGE } from '@shared/components/overview-template/overview-data.constants'

export const DEV_TABS = [
  {
    label: LABEL_USAGE,
    description: '',
    content: `
      import { MatchItemModifier } from '@mediacoach-ui-library/global'

      export class MatchItemPageComponent {
        matchInfoData = matchData['default']
        matchItemModifier = MatchItemModifier

        constructor() { }
      }
    `,
  },
  {
    label: '',
    description: 'Default',
    content: `
      <mc-match-item [match]="matchInfoData"></mc-match-item>
    `,
  },
  {
    label: '',
    description: 'Default / hasState',
    content: `
      <mc-match-item
        [match]="matchInfoData"
        [hasState]="true"
        [type]="matchItemType.Small">
      </mc-match-item>
    `,
  },
  {
    label: '',
    description: 'Type Reduced',
    content: `
      <mc-match-item
        [match]="matchInfoData"
        [type]="matchItemType.Reduced">
      </mc-match-item>
    `,
  },
  {
    label: '',
    description: 'Type Small / hasState',
    content: `
      <mc-match-item
        [match]="matchInfoData"
        [hasState]="true"
        [type]="matchItemType.Small">
      </mc-match-item>
    `,
  },
  {
    label: '',
    description: 'Modifiers Banner / Dark / SizeLg',
    content: `
      <mc-match-item
        [match]="matchInfoData"
        [modifiers]="[
          matchItemModifier.Banner,
          matchItemModifier.Dark,
          matchItemModifier.SizeLg
        ]">
      </mc-match-item>
    `,
  },
]
