import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SharedDataService } from '@core/services/shared-data.service'
import { setOverviewDevData } from '@shared/components/overview-template/overview-data.constants'
import { OverviewData, OverviewDevData } from '@shared/components/overview-template/overview-data.model'
import { DEV_TABS } from './tactical-distances-page.constants'
import * as tacticalDistance from './tactical-distances.json'

@Component({
  selector: 'app-tactical-distances-page',
  templateUrl: './tactical-distances-page.component.html',
  styleUrls: ['./tactical-distances-page.component.scss'],
})
export class TacticalDistancesPageComponent implements OnInit {
  @ViewChild('customComponent', { static: true }) componentPlaceholder: TemplateRef<any>
  overviewData: OverviewData
  overviewDevData: OverviewDevData
  basicConfig: object
  tacticalDistanceHome: object

  constructor(private _route: ActivatedRoute, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.tacticalDistanceHome = this.mapData(tacticalDistance['default'].data.playingFields)

    this.overviewDevData = setOverviewDevData({
      template: this.componentPlaceholder,
      devTabs: DEV_TABS,
      docUrl: 'https://confluence.imagina.tv/pages/viewpage.action?pageId=98767644',
      visualsUrl: 'https://zpl.io/V4QxYDX',
    })

    this._route.data.subscribe((data) => {
      this.overviewData = this._dataService.getComponentData(data, this.tacticalDistanceHome)
      this.overviewDevData.docTabs = this._dataService.getComponentDocData(data)
    })
  }

  mapData(data) {
    return data.map((item) => ({
      type: item.type,
      teamValue: item.teamValue,
      teamAvgValue: item.teamAvgValue,
    }))
  }
}
