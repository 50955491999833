import { Component } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { environment } from '@env'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  buildVersion = environment?.buildVersion

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  })

  constructor(public auth: AngularFireAuth) {}

  login() {
    const {password, email} = this.form.getRawValue()
    this.auth.signInWithEmailAndPassword(email, password).catch(e => console.log(e));
  }
}
