<app-overview-template [data]="overviewData" [devData]="overviewDevData">
  <ng-template #customComponent>
    <div>
      <mc-multiline-diagram
        [dimensions]="sharedMultiLineDiagramDimensions"
        [data]="sharedMultiLineDiagramData"
        [params]="sharedMultiLineDiagramParams">
      </mc-multiline-diagram>
    </div>
  </ng-template>
</app-overview-template>
