<label for="type-selector">Error type: </label>
<select id="type-selector" [(ngModel)]="selectedErrorType">
  <option *ngFor="let value of errorTypes" [value]="value">{{value}}</option>
</select>
<label for="layout-selector">Layout type: </label>
<select id="layout-selector" [(ngModel)]="selectedLayoutType">
  <option *ngFor="let value of layoutTypes" [value]="value">{{value}}</option>
</select>

<div class="error-feedback-page__wrapper">
  <mc-error-feedback
    [errorType]="selectedErrorType"
    [layoutType]="selectedLayoutType">
    <span>Custom content example</span>
  </mc-error-feedback>
</div>
