
export const DEV_TABS = [
  {
    content: `
        <mc-list
          [itemTemplate]="metric"
          [data]="listMockData?.metrics"
        ></mc-list>
        <mc-list
          [itemTemplate]="player"
          [data]="listMockData?.players"
        ></mc-list>
        <mc-list
          [itemTemplate]="common"
          [data]="listMockData?.commons"
        ></mc-list>

      <ng-template #metric let-item>
        <mc-metric-list-item [data]="item"></mc-metric-list-item>
      </ng-template>
      <ng-template #player let-item>
        <mc-player-list-item [data]="item"></mc-player-list-item>
      </ng-template>
      <ng-template #common let-item>
        <mc-common-list-item [data]="item"></mc-common-list-item>
      </ng-template>
    `,
  },
]
